<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      applicationServices: [],
      statusCode: null,
    };
  },
  methods: {
    ...mapMutations(["setDangerMessage"]),
    async getApplicationServices(applicationCompanyId) {
      await axios
        .get(`${WebAPIUrl}/api/applicationcompanyservice/${applicationCompanyId} `)
        .then((result) => {
          this.applicationServices = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async updateApplicationServices(applicationCompanyId, serviceIDs = "") {
      this.processing = true;
      await axios
        .post(
          `${WebAPIUrl}/api/applicationcompanyservice/${applicationCompanyId}?serviceIDs=${serviceIDs}`
        )
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) {
            //   this.applicationID = result.data.applicationID;
            //   this.statusCode = result.status;
          }
        })
        .catch((error) => {
          this.messageBox(error, "Error");
        });
      this.processing = false;
    },
  },
};
</script>
