var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',{staticClass:"form-group parent-child-ques-sec"},[_c('div',{staticClass:"hereone"},[_c('LabelWithInfoTootlip',{attrs:{"label":_vm.question.questionText,"isHoverClickable":true,"tooltipText":_vm.question.tooltip}})],1),_c('div',[_c(_vm.questionTypeAndComp.find(function (ref) {
      var id = ref.id;

      return id === _vm.question.appQuestionInputTypeID;
}).component,{tag:"component",attrs:{"questionID":_vm.question.questionID,"validateField":_vm.validateField,"v":_vm.vali,"type":_vm.questionTypeAndComp.find(function (ref) {
      var id = ref.id;

      return id === _vm.question.appQuestionInputTypeID;
}).type,"selections":_vm.parentQuestionInputTypeId === 9
          ? _vm.contactDetails
          : _vm.question.questionID === 239
          ? _vm.companyPhoneNumbers
          : _vm.question.selections,"disabled":_vm.disabled}}),(
        !_vm.vali.questionsForm[("question_" + (_vm.question.questionID))].required &&
        _vm.vali.questionsForm[("question_" + (_vm.question.questionID))].$dirty
      )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e(),(
        !_vm.vali.questionsForm[("question_" + (_vm.question.questionID))].maxLength &&
        _vm.vali.questionsForm[("question_" + (_vm.question.questionID))].$dirty &&
        _vm.question.maxValidationLength
      )?_c('div',{staticClass:"text-danger"},[_vm._v(" Must have at most "+_vm._s(_vm.vali.questionsForm[("question_" + (_vm.question.questionID))].$params.maxLength.max)+" digits. ")]):_vm._e(),(
        !_vm.vali.questionsForm[("question_" + (_vm.question.questionID))].minLength &&
        _vm.vali.questionsForm[("question_" + (_vm.question.questionID))].$dirty &&
        _vm.question.minValidationLength
      )?_c('div',{staticClass:"text-danger"},[_vm._v(" Must have at least "+_vm._s(_vm.vali.questionsForm[("question_" + (_vm.question.questionID))].$params.minLength.min)+" digits. ")]):_vm._e(),(
        !_vm.vali.questionsForm[("question_" + (_vm.question.questionID))].numeric &&
        _vm.question.appQuestionDataTypeID === 1
      )?_c('div',{staticClass:"text-danger"},[_vm._v(" Must be a number ")]):_vm._e(),(
        !_vm.vali.questionsForm[("question_" + (_vm.question.questionID))].alphaNum &&
        [2, 3].includes(_vm.question.appQuestionDataTypeID) &&
        _vm.question.appQuestionInputTypeID !== 5
      )?_c('div',{staticClass:"text-danger"},[_vm._v(" Must be a string ")]):_vm._e(),(
        !_vm.vali.questionsForm[("question_" + (_vm.question.questionID))].alpha &&
        _vm.question.appQuestionDataTypeID === 4
      )?_c('div',{staticClass:"text-danger"},[_vm._v(" Must be Yes / No ")]):_vm._e(),(
        !_vm.vali.questionsForm[("question_" + (_vm.question.questionID))].decimal &&
        _vm.question.appQuestionDataTypeID === 5
      )?_c('div',{staticClass:"text-danger"},[_vm._v(" Must be a decimal ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }