<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      statusCode: null,
      processing: false,
    };
  },

  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
    ]),
    async getApplicationQuestion(applicationcompanyid) {
      return await axios
        .get(`${WebAPIUrl}/api/application-question/${applicationcompanyid}/all`)
        .then((result) => {
          return result.data.sort((a, b) => a.displayOrder - b.displayOrder);
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async saveApplicationQuestion(payload) {
      await axios
        .post(`${WebAPIUrl}/api/application-question/answer`, payload)
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) {
            this.setInfoMessage(result.data);
          }
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getApplicationQuestionHeaders() {
      return await axios
        .get(`${WebAPIUrl}/api/applicationquestionheader/all`)
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) {
            return result.data;
          }
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
  },
};
</script>
