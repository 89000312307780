<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      statusCode: null,
      processing: false,
      travelAndLogistics: {},
    };
  },

  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
    ]),
    async getTravelAndLogistics(applicationID, applicationCompanyID) {
      await axios
        .get(`${WebAPIUrl}/api/TravelandLogistics/${applicationID}/${applicationCompanyID}`)
        .then((result) => {
          this.travelAndLogistics = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async updateTravelAndLogistics(record) {
      await axios
        .put(`${WebAPIUrl}/api/TravelandLogistics`, record)
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) {
            this.setInfoMessage(result.data);
          }
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
  },
};
</script>
