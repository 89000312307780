<template>
  <b-modal
    id="modalbasic"
    size="lg"
    ref="modalbasic"
    @hide="close()"
    v-model="isVisible"
    :title="this.title"
    :hide-footer="true"
    :no-close-on-backdrop="true"
    @ok="close()"
    @cancel="close()"
  >
    <messages />
    <b-row>
      <b-col>
        <b-card>
          <b-form>
            <b-row class="formrow">
              <b-col lg="6">
                <b-form-group label="Address Line 1:" label-for="nested-addressLine1">
                  <vue-google-autocomplete
                    :id="id"
                    ref="autocomplete"
                    required
                    classname="form-control"
                    placeholder="Search Here..."
                    v-on:placechanged="getAddressData"
                    v-on:change="handleAddressChange"
                    country="us"
                  >
                  </vue-google-autocomplete>
                  <div
                    class="text-danger"
                    v-if="
                      !$v.companyAddress.addressLine1.required &&
                      $v.companyAddress.addressLine1.$dirty
                    "
                  >
                    Field is required!
                  </div>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Address Line 2:" label-for="nested-addressLine2">
                  <b-form-input
                    id="nested-addressLine2"
                    class="col-sm-10"
                    v-model.trim="companyAddress.addressLine2"
                    placeholder="Address Line 2"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="formrow">
              <b-col lg="6">
                <b-form-group label="City:" label-for="nested-city">
                  <b-form-input
                    id="nested-city"
                    class="col-sm-10"
                    v-model.trim="$v.companyAddress.city.$model"
                    placeholder="City"
                    :state="$v.companyAddress.city.$dirty ? !$v.companyAddress.city.$error : null"
                  ></b-form-input>
                  <div
                    class="text-danger"
                    v-if="!$v.companyAddress.city.required && $v.companyAddress.city.$dirty"
                  >
                    Field is required!
                  </div>
                  <div
                    class="text-danger"
                    :state="!$v.companyAddress.city.$error"
                    v-if="!$v.companyAddress.city.minLength"
                  >
                    Must have at least {{ $v.companyAddress.city.$params.minLength.min }} digits.
                  </div>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group>
                  <label>State</label>
                  <b-form-select
                    v-model.trim="$v.companyAddress.stateID.$model"
                    :state="
                      $v.companyAddress.stateID.$dirty ? !$v.companyAddress.stateID.$error : null
                    "
                    :options="states"
                    value-field="stateID"
                    text-field="stateCode"
                    plain
                    @input="$v.companyAddress.$touch"
                  ></b-form-select>
                  <div
                    class="text-danger"
                    v-if="!$v.companyAddress.stateID.required && $v.companyAddress.stateID.$dirty"
                  >
                    Field is required
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="formrow">
              <b-col lg="6">
                <b-form-group label="Zip Code:" label-for="nested-zip">
                  <b-form-input
                    id="nested-zip"
                    class="col-sm-10"
                    v-model.trim="$v.companyAddress.zip.$model"
                    :state="$v.companyAddress.zip.$dirty ? !$v.companyAddress.zip.$error : null"
                    placeholder="Zip Code"
                  ></b-form-input>
                  <div
                    class="text-danger"
                    v-if="!$v.companyAddress.zip.required && $v.companyAddress.zip.$dirty"
                  >
                    Field is required!
                  </div>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group>
                  <div class="tooltip-label">
                    <label id="address-type-label">Address Type(s)</label>&nbsp;
                    <i
                      class="simple-icon-info"
                      v-b-tooltip.hover.right
                      title="If there are no Address Types available to select, it is because those Types have already been assigned to an existing Address within this Company.   You will need to remove them from the Address they're assigned to before assigning those Types to this Address."
                    ></i>
                  </div>
                  <hint class="hint-content"
                    >Billing, Mailing, and Physical address types are required for each location.
                    You can enter an address one time and use the multi-select dropdown to identify
                    all associated address types.</hint
                  >
                  <multiselect
                    v-model="companyAddress.addressTypes"
                    :class="{
                      'multiselect-is-invalid':
                        $v.companyAddress.addressTypes.$error &&
                        $v.companyAddress.addressTypes.$dirty,
                    }"
                    :options="addressTypes"
                    :multiple="true"
                    :close-on-select="false"
                    track-by="addressTypeID"
                    label="addressTypeDesc"
                    placeholder="Select Address Type(s)"
                  >
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="formrow">
              <div class="loginbtnbg">
                <api-button-overlay :processing="processing">
                  <b-button
                    :disabled="$v.$invalid"
                    class="login-blue"
                    @click="saveCompanyAddress()"
                  >
                    <span class="label">Save</span>
                  </b-button>
                </api-button-overlay>
              </div>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>

<style lang="scss" scoped>
.is-invalid {
  border: 1px solid #dc3545 !important;
}

.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}
</style>

<script>
import CompanyAddress from "../../mixins/CompanyAddressMixin.vue";
import { validationMixin } from "vuelidate";
import { mapMutations } from "vuex";
import Multiselect from "vue-multiselect";
import ModalMessages from "../../containers/navs/ModalMessages.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { Hint } from "@progress/kendo-vue-labels";
const { required, minLength } = require("vuelidate/lib/validators");

export default {
  name: "companyAddressModal",
  components: {
    messages: ModalMessages,
    multiselect: Multiselect,
    "vue-google-autocomplete": VueGoogleAutocomplete,
    hint: Hint,
  },
  mixins: [CompanyAddress, validationMixin],

  validations: {
    companyAddress: {
      addressLine1: {
        required,
      },
      city: {
        required,
        minLength: minLength(2),
      },
      stateID: {
        required,
      },
      zip: {
        required,
      },
      addressTypes: {
        required,
      },
    },
  },
  data: () => ({
    title: undefined,
    id: "map1",
    isVisible: false,
    companyAddressID: 0,
  }),

  async created() {
    await this.getAddressTypes();
    await this.getStates();
  },

  methods: {
    ...mapMutations(["setModalDangerMessage", "setModalInfoMessage"]),
    async show(opts = {}) {
      this.title = opts.title;
      if (opts.companyAddressID && opts.companyID) {
        this.getAvailableAddressTypesForAddress(opts.companyAddressID, opts.companyID);
      } else if (opts.companyID) {
        // we want to call this first no matter what.  to clear out any data from when/if the modal was opened before.
        this.initNewAddress(opts.companyID);
        this.getAvailableAddressTypesForCompany(opts.companyID);
      }

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction;
      }

      this.isVisible = true;
      if (opts.companyAddressID) {
        this.companyAddressID = opts.companyAddressID;
        await this.getCompanyAddressByCompanyAddressID(this.companyAddressID);
        this.$refs.autocomplete.update(this.companyAddress.addressLine1 || "");
      }
      // if we have an existing companyAddress, get only the address types available to that address.
      // (the types that are not being used by other addresses of this company)
    },

    close() {
      if (this.exitFunction) {
        this.exitFunction();
      }

      this.companyAddressID = 0;
      this.setModalDangerMessage("");
      this.setModalInfoMessage("");
      this.isVisible = false;
    },
    async saveCompanyAddress() {
      if (this.companyAddressID) {
        await this.updateCompanyAddress(this.companyAddress);
        this.close();
      } else {
        await this.addCompanyAddress(this.companyAddress);
        this.close();
      }
    },
    initNewAddress(companyID) {
      this.companyAddress = {
        // companyAddressID: 0,
        companyID: companyID,
        addressTypeID: undefined,
        addressLine1: "",
        addressLine2: "",
        city: "",
        stateID: undefined,
        zip: "",
        addressTypes: undefined,
      };
    },
    getAddressData: function (addressData) {
      const {
        street_number: streetNumber = "",
        route = "",
        locality = "",
        administrative_area_level_1: stateName = "",
        postal_code: postalCode = "",
      } = addressData;
      const stateId = this.states.find(({ stateCode }) => stateCode === stateName).stateID || "";
      this.companyAddress = {
        ...this.companyAddress,
        addressLine1: `${streetNumber} ${route}`,
        city: locality,
        stateID: stateId,
        zip: postalCode,
      };
      // wait some time to handleAddressChange reset the input field
      setTimeout(() => {
        this.$refs.autocomplete.update(`${streetNumber} ${route}`);
      }, 500);
    },
    handleAddressChange: function () {
      this.$refs.autocomplete.update("");
    },
    validateCompanyInfo(prop) {
      const { $dirty, $error } = this.$v.companyAddress[prop];
      return $dirty ? !$error : null;
    },
  },
};
</script>
<style>
.tooltip-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

#address-type-label {
  margin-bottom: 0;
}

/* Google Auto complete suggestion adjust  */
.pac-container {
  z-index: 1050;
}
.hint-content {
  margin-bottom: 0.5rem;
  margin-top: 0 !important;
}
</style>
