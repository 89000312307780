<template>
  <div class="inline-box">
    <b-form-checkbox
      v-for="option in selections"
      :key="`option_${option.value}`"
      :id="`checkbox-${option.value}`"
      v-model="v.questionsForm[`question_${questionID}`].$model"
      :name="`checkbox-${option.value}`"
      :state="validateField(`question_${questionID}`)"
      :value="option.value"
      unchecked-value=""
      :disabled="disabled"
    >
      {{ option.text }}
    </b-form-checkbox>
  </div>
</template>
<script>
export default {
  props: ["questionID", "validateField", "v", "selections", "disabled"],
};
</script>
<style scoped>
.inline-box {
  display: flex;
}
</style>
