<template>
  <div class="full-width">
    <section class="form-full-width">
      <div>
        <div>
          <p>
            <u>Hours of Operation</u>
          </p>
        </div>
        <p class="desc-text" v-if="!dailyHoursToggle">
          Enter your most common daily hours of operation.
        </p>

        <p class="desc-text" v-else>
          Edit your daily hours of operation if they are not correctly displayed below.
        </p>

        <div>
          <b-row class="formrow">
            <b-col lg="12">
              <b-form-group label-for="hoursOfOperation">
                <div v-if="dailyHours.hoursOfOperation.length">
                  <div
                    v-for="(day, index) in dailyHours.hoursOfOperation"
                    :key="index"
                    class="d-flex mt-2"
                    style="align-items: center"
                  >
                    <b-form-checkbox
                      class="w-50 ps-0"
                      :name="day.weekDays"
                      :id="day.weekDays"
                      unchecked-value="false"
                      :checked="day.startTime !== null && day.endTime !== null"
                      @change="onWeekDayChange($event, index)"
                      :disabled="disableField"
                    >
                      {{ day.weekDays }}
                    </b-form-checkbox>
                    <timepicker
                      name="startTime"
                      @change="handleTimingHoursChanges($event, index)"
                      :disabled="disableField || day.startTime === null"
                      :value="generateValidateTime(day.startTime)"
                    />
                    <timepicker
                      name="endTime"
                      :value="generateValidateTime(day.endTime)"
                      @change="handleTimingHoursChanges($event, index)"
                      class="ms-2"
                      :disabled="disableField || day.endTime === null"
                    />
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <hr />
      <div>
        <p>
          <LabelWithInfoTootlip
            label="
                <b>
                  <u>Day of Survey Contact</u>
                </b>
              "
            tooltipText="This information will be used by the surveyor on the first day of survey. If you do not see the correct contact listed in the dropdown you may go back to the Contacts page on the application or the Manage Contacts quick link on the dashboard and add the correct contact. Once added, the contact will display here."
          />
        </p>
        <b-row class="formrow">
          <b-col lg="12">
            <b-form-group label="Name">
              <multiselect
                :disabled="disableField"
                :class="{
                  'multiselect-is-invalid': $v.dailyHours.surveyContactName.$dirty
                    ? $v.dailyHours.surveyContactName.$error
                    : null,
                }"
                v-model="dailyHours.surveyContactName"
                :options="companyContactList"
                :multiple="false"
                :close-on-select="true"
                placeholder="Select Name"
                track-by="contactID"
                :custom-label="contactNameCustomLabel"
                @input="handleChangeContactName"
              ></multiselect>
              <div
                class="text-danger"
                v-if="
                  !$v.dailyHours.surveyContactName.required &&
                  $v.dailyHours.surveyContactName.$dirty
                "
              >
                This field is required.
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="formrow">
          <b-col lg="12">
            <b-form-group label="Phone">
              <multiselect
                :disabled="disableField"
                :class="{
                  'multiselect-is-invalid': $v.dailyHours.surveyContactNumber.$dirty
                    ? $v.dailyHours.surveyContactNumber.$error
                    : null,
                }"
                v-model="dailyHours.surveyContactNumber"
                :options="contactPhone"
                :multiple="false"
                :close-on-select="true"
                placeholder="Select Phone"
                track-by="companyContactPhoneID"
                :custom-label="contactPhoneCustomLabel"
                @change="handleChangeContactPhone"
              >
                <span slot="noOptions"> Please add a phone number to your Company Contact </span>
              </multiselect>
              <div
                class="text-danger"
                v-if="
                  !$v.dailyHours.surveyContactNumber.required &&
                  $v.dailyHours.surveyContactNumber.$dirty
                "
              >
                This field is required.
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="formrow" v-if="contactPhone.length > 1">
          <b-col lg="12">
            <b-form-group label="Alternate Phone">
              <multiselect
                :disabled="disableField"
                v-model="dailyHours.surveyContactNumberAlt"
                :options="getAlternatePhones"
                :multiple="false"
                :close-on-select="true"
                placeholder="Select Phone"
                track-by="companyContactPhoneID"
                :custom-label="contactPhoneCustomLabel"
                :hide-selected="true"
              ></multiselect>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <hr />
      <div>
        <p>
          <b><u>Surveyor Travel</u></b>
        </p>
        <p>
          Please recommend two major or regional airports close to the main site. Please list major
          airports first:
        </p>
        <b-row class="formrow">
          <b-col lg="12">
            <b-form-group label="Closest Recommended Airport">
              <b-form-input
                :disabled="disableField"
                v-model.trim="dailyHours.closestAirport"
                :state="
                  $v.dailyHours.closestAirport.$dirty ? !$v.dailyHours.closestAirport.$error : null
                "
                @input="$v.dailyHours.closestAirport.$touch"
              ></b-form-input>
              <div
                class="text-danger"
                v-if="!$v.dailyHours.closestAirport.required && $v.dailyHours.closestAirport.$dirty"
              >
                This field is required.
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="formrow">
          <b-col lg="12">
            <b-form-group label="Next Closest Recommended Airport">
              <b-form-input
                :disabled="disableField"
                v-model.trim="dailyHours.closestAirportAlt"
                @input="$v.dailyHours.closestAirportAlt.$touch"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <p>
          Please recommend two reasonably priced hotel/motels close to the main site. If possible,
          list hotels within a short distance and with full-service restaurant capability.
        </p>
        <b-row class="formrow">
          <b-col lg="12">
            <b-form-group label="Closest Recommended Hotel">
              <b-form-input
                :disabled="disableField"
                v-model.trim="dailyHours.closestHotel"
                :state="
                  $v.dailyHours.closestHotel.$dirty ? !$v.dailyHours.closestHotel.$error : null
                "
                @input="$v.dailyHours.closestHotel.$touch"
              ></b-form-input>
              <div
                class="text-danger"
                v-if="!$v.dailyHours.closestHotel.required && $v.dailyHours.closestHotel.$dirty"
              >
                This field is required.
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="formrow">
          <b-col lg="12">
            <b-form-group label="Next Closest Recommended Hotel">
              <b-form-input
                :disabled="disableField"
                v-model.trim="dailyHours.closestHotelAlt"
                @input="$v.dailyHours.closestHotelAlt.$touch"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <p>
          Question description: Please recommend two reasonably priced restaurants within a safe
          walking distance, or accessible via short ride from the recommended hotel(s).
        </p>
        <b-row class="formrow">
          <b-col lg="12">
            <b-form-group label="Closest Recommended Restaurant">
              <b-form-input
                :disabled="disableField"
                v-model.trim="dailyHours.closestRestaurant"
                :state="
                  $v.dailyHours.closestRestaurant.$dirty
                    ? !$v.dailyHours.closestRestaurant.$error
                    : null
                "
                @input="$v.dailyHours.closestRestaurant.$touch"
              ></b-form-input>
              <div
                class="text-danger"
                v-if="
                  !$v.dailyHours.closestRestaurant.required &&
                  $v.dailyHours.closestRestaurant.$dirty
                "
              >
                This field is required.
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="formrow">
          <b-col lg="12">
            <b-form-group label="Next Closest Recommended Restaurant">
              <b-form-input
                :disabled="disableField"
                v-model.trim="dailyHours.closestRestaurantAlt"
                @input="$v.dailyHours.closestRestaurantAlt.$touch"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="formrow">
          <b-col lg="12">
            <b-form-group>
              <label>
                <input
                  :disabled="disableField"
                  v-model="dailyHours.freeParkingAccess"
                  class="standard-checkbox"
                  type="checkbox"
                />
                <div class="standard-info-group">
                  <p class="bold-gray-text mb-4">
                    Click here if parking is not free or if there are special parking instructions.
                  </p>
                </div>
              </label>
            </b-form-group>
            <b-form-group label="Parking Instructions" v-if="dailyHours.freeParkingAccess">
              <b-form-input
                v-model.trim="dailyHours.freeParkingAccessInstructions"
                :state="
                  $v.dailyHours.freeParkingAccessInstructions.$dirty
                    ? !$v.dailyHours.freeParkingAccessInstructions.$error
                    : null
                "
                @input="$v.dailyHours.freeParkingAccessInstructions.$touch"
              ></b-form-input>
              <div
                class="text-danger"
                v-if="
                  !$v.dailyHours.freeParkingAccessInstructions.required &&
                  $v.dailyHours.freeParkingAccessInstructions.$dirty
                "
              >
                This field is required.
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="formrow">
          <b-col lg="12">
            <label>
              <input
                :disabled="disableField"
                v-model="dailyHours.specialBuildingAccess"
                class="standard-checkbox"
                type="checkbox"
              />
              <div class="standard-info-group">
                <p class="bold-gray-text mb-4">
                  Click here if there are special instructions to gain access to your organization.
                </p>
              </div>
            </label>
            <b-form-group label="Instructions" v-if="dailyHours.specialBuildingAccess">
              <b-form-input
                :disabled="disableField"
                v-model.trim="dailyHours.specialBuildingAccessInstructions"
                :state="
                  $v.dailyHours.specialBuildingAccessInstructions.$dirty
                    ? !$v.dailyHours.specialBuildingAccessInstructions.$error
                    : null
                "
                @input="$v.dailyHours.specialBuildingAccessInstructions.$touch"
              ></b-form-input>
              <div
                class="text-danger"
                v-if="
                  !$v.dailyHours.specialBuildingAccessInstructions.required &&
                  $v.dailyHours.specialBuildingAccessInstructions.$dirty
                "
              >
                This field is required.
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </section>
  </div>
</template>
<script>
import { TimePicker } from "@progress/kendo-vue-dateinputs";
import TravelandLogisticsMixin from "../../../mixins/TravelandLogisticsMixin.vue";
import moment from "moment/moment.js";
// import { Button } from "@progress/kendo-vue-buttons";
import { validationMixin } from "vuelidate";
const { required, requiredIf } = require("vuelidate/lib/validators");
import { mapGetters, mapMutations } from "vuex";
import CompanyContactMixin from "../../../mixins/CompanyContactMixin.vue";
import Multiselect from "vue-multiselect";
import PhoneMixin from "../../../mixins/PhoneMixin.vue";
import DirtyDataMixin from "../../../mixins/DirtyDataMixin.vue";
import { bus } from "../../../main";
import LabelWithInfoTootlip from "../../../components/Common/LabelWithInfoTooltip.vue";
import { getApplicationCompanyId } from "../../../utils/customFunctions";

export default {
  components: {
    timepicker: TimePicker,
    // "k-button": Button,
    multiselect: Multiselect,
    LabelWithInfoTootlip,
  },
  mixins: [
    TravelandLogisticsMixin,
    validationMixin,
    CompanyContactMixin,
    PhoneMixin,
    DirtyDataMixin,
  ],
  props: ["applicationCompanyID"],
  validations: {
    dailyHours: {
      surveyContactName: {
        required,
      },
      surveyContactNumber: {
        required,
      },
      closestAirport: {
        required,
      },
      closestAirportAlt: {},
      closestHotel: {
        required,
      },
      closestHotelAlt: {},
      closestRestaurant: {
        required,
      },
      closestRestaurantAlt: {},
      freeParkingAccessInstructions: {
        required: requiredIf(function () {
          return this.dailyHours.freeParkingAccess;
        }),
      },
      specialBuildingAccessInstructions: {
        required: requiredIf(function () {
          return this.dailyHours.specialBuildingAccess;
        }),
      },
    },
  },
  async created() {
    const { applicationID = 0 } = this.$route.params;
    this.dailyHours.applicationID = parseInt(applicationID);
    const applicationCompanyId = getApplicationCompanyId();
    await this.getTravelAndLogistics(applicationID, applicationCompanyId);
    const {
      hoursOfOperation = [],
      surveyContactName = null,
      surveyContactNumber = null,
      surveyContactNumberAlt = "",
    } = this.travelAndLogistics;
    this.dailyHours = {
      ...this.dailyHours,
      ...this.travelAndLogistics,
      hoursOfOperation: hoursOfOperation.length
        ? hoursOfOperation
        : this.dailyHours.hoursOfOperation,
    };
    if (hoursOfOperation.length) {
      this.dailyHoursToggle = true;
    }
    await this.getCompanyContactsTable(this.$route.params.companyID);
    this.companyContactList = this.companyContactsTable;
    await this.getPhoneTypes();
    if (surveyContactName) {
      const record = surveyContactName.split(" "); // To Get First Name and Last Name
      this.dailyHours.surveyContactName = this.companyContactList.find(
        ({ firstName, lastName }) => firstName == record[0] && lastName == record[1]
      );
      this.dailyHours.surveyContactName &&
        (await this.getCompanyContactPhoneByContactId(this.dailyHours.surveyContactName.contactID));
      if (surveyContactNumber) {
        const phone = surveyContactNumber.replace(/\D+/g, "");
        this.dailyHours.surveyContactNumber = this.contactPhone.find(
          ({ phoneNumber }) => phoneNumber == phone
        );
      }
      if (surveyContactNumberAlt) {
        const phoneAlt = surveyContactNumberAlt.replace(/\D+/g, "");
        this.dailyHours.surveyContactNumberAlt = this.contactPhone.find(
          ({ phoneNumber }) => phoneNumber == phoneAlt
        );
      }
    }
    this.initDirtyDataWatcher("dailyHours");
  },
  mounted() {
    bus.$on("applicationStatus", async (data) => {
      this.disableField = data;
    });
    this.$v.dailyHours.$touch();
  },
  beforeDestroy() {
    bus.$off("applicationStatus");
  },
  data() {
    const defaultStartTime = moment();
    defaultStartTime.hour(9).minute(0).second(0).millisecond(0);

    const defaultEndTime = moment();
    defaultEndTime.hour(17).minute(0).second(0).millisecond(0);

    return {
      dailyHours: {
        openTime: null,
        closeTime: null,
        openOnWeekends: true,
        hoursOfOperation: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ].map((day, index) => {
          return {
            startTime: day === "Saturday" || day === "Sunday" ? null : defaultStartTime.format(),
            endTime: day === "Saturday" || day === "Sunday" ? null : defaultEndTime.format(),
            displayOrder: index + 1,
            weekDays: day,
          };
        }),
        applicationID: 0,
        surveyContactName: {},
        surveyContactNumber: {},
        surveyContactNumberAlt: {},
        closestAirport: "",
        closestHotel: "",
        closestAirportAlt: "",
        closestHotelAlt: "",
        freeParkingAccess: false,
        freeParkingAccessInstructions: "",
        closestRestaurant: "",
        closestRestaurantAlt: "",
        specialBuildingAccess: false,
        specialBuildingAccessInstructions: "",
      },
      dailyHoursToggle: false,
      days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      companyContactList: [],
      phoneList: [],
      applicationStatus: 0,
      disableField: false,
    };
  },
  methods: {
    ...mapMutations(["setInfoMessage"]),
    async saveTravelAndLogistics() {
      const {
        surveyContactName = {},
        surveyContactNumber = {},
        surveyContactNumberAlt = {},
      } = this.dailyHours;
      let altPhoneNumber = undefined;
      //10/28/22 - does alt phone number need to be required? i would assume not
      if (surveyContactNumberAlt) {
        if (
          Object.keys(surveyContactNumberAlt).length !== 0 &&
          Object.getPrototypeOf(surveyContactNumberAlt) === Object.prototype
        ) {
          altPhoneNumber = surveyContactNumberAlt
            ? this.formatPhoneNumberWithType(
                surveyContactNumberAlt.phoneNumber,
                surveyContactNumberAlt.phoneNumberType
              )
            : "";
        }
      }

      const payload = {
        ...this.dailyHours,
        surveyContactName:
          surveyContactName && Object.keys(surveyContactName).length
            ? `${surveyContactName.firstName} ${surveyContactName.lastName}`
            : "",
        surveyContactNumber:
          surveyContactNumber && Object.keys(surveyContactNumber).length
            ? this.formatPhoneNumberWithType(
                surveyContactNumber.phoneNumber,
                surveyContactNumber.phoneNumberType
              )
            : "",
        surveyContactNumberAlt: altPhoneNumber,
        openTime: undefined,
        closeTime: undefined,
        openOnWeekends: undefined,
        applicationCompanyID: parseInt(this.applicationCompanyID),
      };

      await this.updateTravelAndLogistics(payload);
      if (this.statusCode === 200) {
        this.$v.dailyHours.$touch();

        if (!this.$v.dailyHours.$error) {
          return true;
        } else {
          // this.setDangerMessage("Form must be filled out properly.");
          return false;
        }
      }
    },
    getFormattedTimeData(date) {
      return moment(date, "YYYY-MM-DD").format("hh:mm A");
    },
    contactNameCustomLabel({ contactID }) {
      const contacts = this.companyContactList.find((record) => record.contactID === contactID);
      return contacts ? `${contacts.firstName} ${contacts.lastName}` : "";
    },
    loadPhoneDescription(phoneTypeID) {
      return (
        this.phoneTypes &&
        this.phoneTypes.length &&
        this.phoneTypes.find((pt) => pt.phoneNumberTypeID === phoneTypeID).phoneNumberTypeDesc
      );
    },
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    formatPhoneNumberWithType(phoneNumber, phoneNumberType) {
      return `${this.formatPhoneNumber(phoneNumber)} - ${this.loadPhoneDescription(
        phoneNumberType
      )}`;
    },
    contactPhoneCustomLabel({ companyContactPhoneID }) {
      const phones = this.contactPhone.find(
        (record) => record.companyContactPhoneID === companyContactPhoneID
      );
      return phones
        ? this.formatPhoneNumberWithType(phones.phoneNumber, phones.phoneNumberType)
        : "";
    },
    async handleChangeContactName() {
      const { contactID = 0 } = this.dailyHours.surveyContactName;
      await this.getCompanyContactPhoneByContactId(contactID);
    },
    handleChangeContactPhone() {
      this.dailyHours.surveyContactNumberAlt = "";
    },
    generateValidateTime: function (date) {
      if (date) {
        return new Date(date);
      }
      return null;
    },
    handleTimingHoursChanges(event, index) {
      const { name, value } = event.target;
      this.dailyHours.hoursOfOperation[index][name] = moment(value).format();
    },
    onWeekDayChange(event, index) {
      const defaultStartTime = moment();
      defaultStartTime.hour(9).minute(0).second(0).millisecond(0);
      const defaultEndTime = moment();
      defaultEndTime.hour(17).minute(0).second(0).millisecond(0);

      this.dailyHours.hoursOfOperation[index]["startTime"] =
        event === true ? defaultStartTime.format() : null;
      this.dailyHours.hoursOfOperation[index]["endTime"] =
        event === true ? defaultEndTime.format() : null;
    },
  },
  computed: {
    ...mapGetters({
      getCompanyContact: "getCompanyContact",
    }),
    companyContactsTable() {
      return this.getCompanyContact;
    },
    getAlternatePhones() {
      if (this.contactPhone.length && this.dailyHours.surveyContactNumber) {
        const alternateNumbers = this.contactPhone.filter(
          ({ companyContactPhoneID }) =>
            companyContactPhoneID !== this.dailyHours.surveyContactNumber.companyContactPhoneID
        );
        return alternateNumbers;
      } else {
        return [];
      }
    },
  },
};
</script>
<style scoped>
.full-width {
  width: 100%;
}
.desc-text {
  font-size: 13px;
}
</style>
