<template>
  <div
    :style="{
      display: 'flex',
      'flex-direction': 'column',
    }"
  >
    <center>
      <h1 class="bold-header-text app-header mb-3">
        <span
          >Company : <b>{{ $route.params.companyID }}</b
          >, Application : <b>{{ $route.params.applicationID }}</b></span
        >
      </h1>
    </center>
    <Stepper :value="step" :items="steps" @change="stepperChange" :item="'CustomizedStep'">
      <template v-slot:CustomizedStep="{ props }">
        <Step v-bind="props" @change="(e) => props.onChange(e)">
          <span
            class="k-step-indicator"
            aria-hidden="true"
            :style="displayWarning(props) ? disabledStepStyle : ''"
          >
            <Tooltip
              :content="'myTooltipTemplate'"
              :anchor-element="'target'"
              :position="'top'"
              :auto-hide="true"
            >
              <span
                class="k-step-indicator-icon k-icon"
                :class="props.icon"
                :title="displayWarning(props)"
              />
              <template v-slot:myTooltipTemplate="{ props }">
                <a>
                  <strong>{{ props.title }}</strong>
                </a>
              </template>
            </Tooltip>
          </span>
          <span class="k-step-label">
            <span class="k-step-text">{{ props.label }}</span>
            <span
              aria-hidden="true"
              class="k-step-indicator-icon k-icon"
              :class="props.isValid ? 'k-i-check-circle' : 'k-i-exclamation-circle'"
            ></span>
          </span>
        </Step>
      </template>
    </Stepper>

    <div class="mt-2">
      <confirmation ref="Confirmation"></confirmation>

      <div
        :style="{
          display: 'flex',
          'justify-content': 'space-between',
        }"
        :class="'k-form-buttons'"
      >
        <h5 class="bold-header-text app-header mb-3">
          <span>{{ stepLable[step] }}</span>
        </h5>
        <span>
          <span v-if="step !== 0">
            <k-button
              :style="{ 'margin-right': '16px ' }"
              class="btn btn-blue"
              @click="prevButtonClick"
            >
              Previous
            </k-button>
          </span>
          <k-button
            class="btn btn-blue"
            @click="() => nextSubmitButtonClick(true, null)"
            v-if="!this.isLastStep"
          >
            Next
          </k-button>
        </span>
      </div>
      <br />
      <k-form ref="form" :class="'form-element'">
        <div>
          <companyInfo
            ref="companyInfo"
            v-show="step === 0"
            :parentApplicationCompanyID="applicationCompanyID"
            v-if="!applicationCompanyDetailProcessing && applicationCompanyID"
          />
          <CompanyContact
            ref="companyContact"
            v-show="step === 1"
            v-if="!applicationCompanyDetailProcessing && applicationCompanyID"
          />
          <ProgramSpecific
            ref="ProgramSpecific"
            v-show="step === 2"
            v-if="!applicationCompanyDetailProcessing && applicationCompanyID"
            :questionsList="formattedQuestionsList"
            :allQuestions="questionsList"
            :linear="true"
            :applicationCompanyID="applicationCompanyID"
            :programSpecificList="programSpecificList"
            :childList="childList"
            :mainApplicationStatusTypeId="applicationStatusTypeId"
            :companyID="$route.params.companyID"
          />
          <TravelAndLogistics
            ref="TravelAndLogistics"
            v-show="step === 3"
            :applicationCompanyID="applicationCompanyID"
            v-if="!applicationCompanyDetailProcessing && applicationCompanyID"
          />
          <Affiliations
            ref="Affiliations"
            v-show="step === 4"
            v-if="!applicationCompanyDetailProcessing && applicationCompanyID"
          />
          <ShoppingCart
            ref="ShoppingCart"
            v-show="step === 5"
            from="application-company-shopping"
            :onStep="step"
            :applicationCompanyID="applicationCompanyID"
            v-if="applicationCompanyID && !applicationCompanyDetailProcessing"
          />
        </div>
      </k-form>
    </div>

    <span :style="{ 'margin-top': '40px' }" :class="'k-form-separator'" />
    <div
      :style="{
        'justify-content': 'flex-end',
        'align-content': 'center',
      }"
      :class="'k-form-buttons'"
    >
      <span>
        <span v-if="step !== 0">
          <k-button
            :style="{ 'margin-right': '16px ' }"
            class="btn btn-blue"
            @click="prevButtonClick"
          >
            Previous
          </k-button>
        </span>
        <k-button class="btn btn-blue" @click="() => nextSubmitButtonClick(true, null)">
          {{ isLastStep ? "Save" : "Next" }}
        </k-button>
      </span>
    </div>
  </div>
</template>
<script>
import { Stepper, Step } from "@progress/kendo-vue-layout";
import { Form } from "@progress/kendo-vue-form";
import { Button } from "@progress/kendo-vue-buttons";
import companyInfo from "./CompanyInfo.vue";
import CompanyContact from "./CompanyContact.vue";
import TravelAndLogistics from "./TravelAndLogistics.vue";
import ProgramSpecific from "./ProgramSpecific.vue";
import ProgramSpecificMixin from "../../../mixins/ProgramSpecificMixin.vue";
import applicationCompanyMixin from "../../../mixins/applicationCompanyMixin.vue";
import ShoppingCart from "../../../views/app/ShoppingCart.vue";
import ShoppingCartMixin from "../../../mixins/ShoppingCartMixin.vue";
import Affiliations from "./Affiliations.vue";
import DirtyDataMixin from "../../../mixins/DirtyDataMixin.vue";
import { bus } from "../../../main";
import { routePaths } from "../../../utils/customConstants";
import { getCurrentCompany } from "../../../utils/customFunctions";
import { Tooltip } from "@progress/kendo-vue-tooltip";
import Confirmation from "../../../components/Common/Confirmation.vue";

export default {
  components: {
    "k-form": Form,
    Stepper,
    Step,
    "k-button": Button,
    companyInfo,
    TravelAndLogistics,
    CompanyContact,
    ProgramSpecific,
    Affiliations,
    ShoppingCart,
    Tooltip,
    Confirmation,
  },
  mixins: [ProgramSpecificMixin, applicationCompanyMixin, DirtyDataMixin, ShoppingCartMixin],

  data() {
    return {
      disableNextStep: true,
      childList: [],
      step: 0,
      stepLable: {
        0: "About Your Organization",
        1: "Contacts",
        2: "Program-Specific",
        3: "Travel and Logistics",
        4: "Affiliations & Discounts",
        5: "",
      },
      steps: [
        {
          label: "About Your Organization",
          componentName: "CompanyInfo",
          icon: "k-i-user",
          isValid: false,
        },
        {
          label: "Contacts",
          componentName: "CompanyContact",
          icon: "k-i-paste-plain-text",
          isValid: false,
        },
        {
          label: "Program-Specific",
          icon: "k-i-track-changes-accept",
          componentName: "ProgramSpecific",
          isValid: false,
        },
        {
          label: "Travel and Logistics",
          icon: "k-i-cart",
          componentName: "TravelAndLogistics",
          isValid: false,
        },
        {
          label: "Affiliations",
          icon: "k-i-preview",
          componentName: "Affiliations",
          isValid: false,
        },
      ],
      companyId: "",
      questionsList: [],
      applicationCompanyID: null,
      formattedQuestionsList: [],
      programSpecificList: [],
      allStepsCompleted: false,
      tooltipText: "",
      disabledStepStyle: { backgroundColor: "#74a2bf !important" },
      isMainLocation: false,
      applicationStatusTypeId: null,
    };
  },
  async created() {
    this.step = parseInt(
      Object.keys(routePaths).find((routes) => routePaths[routes] === this.$route.params.step)
    );
    const { companyID } = this.$route.params;
    const currentCompany = getCurrentCompany();
    this.companyId = this.$route.params.companyID;
    await this.getApplicationCompanyDetails(this.$route.params.applicationID, this.companyId);

    this.applicationCompanyID = this.applicationCompanyDetail.applicationCompanyID;
    this.applicationStatusTypeId = this.applicationCompanyDetail.applicationTypeID;

    localStorage.setItem("APPLICATION_COMPANY_ID", this.applicationCompanyID);
    await this.getApplicationCompany(this.applicationCompanyID);
    const isDisabled = [30, 31].includes(this.applicationCompanyData.applicationStatusID);
    bus.$emit("applicationStatus", isDisabled);
    // Doposit tab only available when parent and current company Id are same (Main Location)
    if (currentCompany.companyID === parseInt(companyID)) {
      this.isMainLocation = true;
      this.steps.push({
        label: "Deposit",
        icon: "k-i-dollar",
        componentName: "ShoppingCart",
        isValid: false,
      });
    }
    bus.$on("dirtyModalSaveChanges", (to) => {
      this.nextSubmitButtonClick(false, null);
      this.$router.push(to.fullPath);
    });
    this.questionsList = await this.getApplicationQuestion(this.applicationCompanyID);
    const allHeaders = await this.getApplicationQuestionHeaders();

    //Restructure Headers with Sub Header
    const newHeaderStruc = allHeaders
      .filter(({ parentHeaderID }) => !parentHeaderID)
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map((record) => {
        const subHeaders = allHeaders.filter(
          ({ parentHeaderID }) => parentHeaderID === record.appQuestionSectionHeaderID
        );
        return {
          ...record,
          subHeaders,
        };
      });
    // Find Child Questions
    const childQuestions = [];
    this.questionsList.forEach((record) => {
      const dropOptions = record.selections;
      if (
        [2, 4, 5, 8, 9].includes(record.appQuestionInputTypeID) &&
        dropOptions &&
        dropOptions.length
      ) {
        dropOptions
          .filter(({ childQuestion }) => childQuestion)
          .forEach((item) => {
            const childItem = item.childQuestion.split(",");
            childItem.forEach((child) => {
              childQuestions.push(child);
            });
          });
      }
    });
    this.childList = childQuestions;
    // Main Questions
    const mainQuestions = this.questionsList.filter(
      ({ questionID }) => !childQuestions.includes(questionID.toString())
    );
    this.programSpecificList = mainQuestions.reduce((temp, curVal) => {
      const { programID, programDescription, appQuestionSectionHeaderID } = curVal;
      const index = temp.findIndex((rec) => rec.programID === programID);
      if (index >= 0) {
        // If Already exists
        let existHeaders = temp[index].headers;
        // only add when it's not duplicate
        if (!existHeaders.includes(appQuestionSectionHeaderID)) {
          existHeaders.push(appQuestionSectionHeaderID);
        }
        temp[index] = {
          ...temp[index],
          headers: existHeaders,
        };
      } else {
        // add as new when it's not duplicate
        temp.push({
          programID,
          programDescription,
          headers: [appQuestionSectionHeaderID],
        });
      }
      return temp;
    }, []);

    // Questions New Format (Header-Sub header wise)
    this.formattedQuestionsList = newHeaderStruc.map((record) => {
      // applicationTypeID = 1 is Regular Question
      // applicationTypeID = 2 is Renewal Question
      const relQuestions = mainQuestions
        .filter(
          ({ appQuestionSectionHeaderID, applicationTypeID }) =>
            appQuestionSectionHeaderID === record.appQuestionSectionHeaderID &&
            (applicationTypeID === 1 || applicationTypeID === this.applicationStatusTypeId)
        )
        .map((ques) => {
          return {
            ...ques,
            subQuestions: this.getSubQuestions(ques),
          };
        })
        .map((ques) => {
          let subQuestions = ques.subQuestions;
          if (ques.subQuestions) {
            subQuestions = ques.subQuestions.map((subQues) => {
              return {
                ...subQues,
                subQuestions: this.getSubQuestions(subQues),
              };
            });
          }
          return {
            ...ques,
            subQuestions,
          };
        });

      let newSubHeaders = [];
      newSubHeaders = record.subHeaders.map((rec) => {
        const relQuestions2 = mainQuestions
          .filter(
            ({ appQuestionSectionHeaderID, applicationTypeID }) =>
              appQuestionSectionHeaderID === rec.appQuestionSectionHeaderID &&
              (applicationTypeID === 1 || applicationTypeID === this.applicationStatusTypeId)
          )
          .map((ques) => {
            return {
              ...ques,
              subQuestions: this.getSubQuestions(ques),
            };
          })
          .map((ques) => {
            let subQuestions = ques.subQuestions;
            if (ques.subQuestions) {
              subQuestions = ques.subQuestions.map((subQues) => {
                return {
                  ...subQues,
                  subQuestions: this.getSubQuestions(subQues),
                };
              });
            }
            return {
              ...ques,
              subQuestions,
            };
          });
        return {
          ...rec,
          questions: relQuestions2,
        };
      });
      return {
        ...record,
        questions: relQuestions,
        subHeaders: newSubHeaders,
      };
    });
    this.completedSteps();
  },
  destroyed() {
    bus.$off("dirtyModalSaveChanges");
  },
  computed: {
    isLastStep() {
      return this.steps.length - 1 === this.step;
    },
  },
  methods: {
    // Display only for last step
    displayWarning({ index, numOfSteps }) {
      return index === numOfSteps - 1 && this.isMainLocation ? this.tooltipText : "";
    },
    async stepperChange(event) {
      let _this = this;
      // Common Statements
      async function handleStep() {
        _this.nextSubmitButtonClick(true, event.value);
        // if (_this.step === 5) {
        //   await _this.addItemToCart(6, _this.$route.params.applicationID);
        //   bus.$emit("updateCart");
        // }
      }
      let allowToMove = true;
      // if last step
      if (this.isMainLocation && event.value === this.steps.length - 1) {
        if (!this.allStepsCompleted) allowToMove = false;
      }
      allowToMove && handleStep();
    },
    async completedSteps(step) {
      await this.getCompletedApplicationSteps(this.applicationCompanyID);
      if (this.companyApplicationCompletedSteps.length) {
        // Get only valid steps
        this.companyApplicationCompletedSteps
          .filter(({ applicationStepID }) => applicationStepID)
          .forEach(({ applicationStepID, isComplete }) => {
            this.steps[applicationStepID - 1].isValid = isComplete;
          });

        //completed steps count
        let completedStepsCount = this.companyApplicationCompletedSteps.filter(
          ({ isComplete }) => isComplete
        ).length;
        const lastStep = this.steps.length - 1;
        if (completedStepsCount === lastStep) this.allStepsCompleted = true;
      }
      //Warning for last step tooltip
      const incompletedSteps = this.steps
        .filter(({ isValid }, index) => !isValid && index !== this.steps.length - 1)
        .map(({ label }) => label)
        .join(", ");
      if (incompletedSteps) {
        this.tooltipText = `The application isn't complete.  Please finish filling out the ${incompletedSteps} screens`;
        if (step === 4 && this.tooltipText) {
          this.$refs.Confirmation.show({
            title: "Warning",
            message: this.tooltipText,
            exitFunction: () => {},
          });
        }
      }
    },
    async nextSubmitButtonClick(redirect = true, targetRoute = null) {
      this.formErrors = this.$refs.form.form.errors;
      let res = true;
      bus.$emit("showFullpageOverlay", {
        showLayover: true,
      });
      if (this.isLastStep) {
        // this.$refs.TravelAndLogistics.saveCompanyAddress(); commented out because this is not the last step
        this.showErrors = false;
        const formValues = this.$refs.form.form.values;
        for (const property in formValues) {
          if (formValues[property] === null || formValues[property] === "") {
            this.showErrors = true;
          }
        }
      } else {
        switch (this.step) {
          case 0:
            res = await this.$refs.companyInfo.saveCompanyDetails();
            break;
          case 1:
            res = await this.$refs.companyContact.isValidationSuccess();
            break;
          case 2:
            res = await this.$refs.ProgramSpecific.saveProgramSpecific();
            break;
          case 3:
            res = await await this.$refs.TravelAndLogistics.saveTravelAndLogistics();
            break;
          case 4:
            res = await this.$refs.Affiliations.saveCompanyAffiliates();
            // await this.addItemToCart(6, this.$route.params.applicationID);
            // bus.$emit("updateCart");
            break;

          default:
            break;
        }
      }
      const payload = {
        applicationCompanyID: this.applicationCompanyID,
        applicationStepID: this.step + 1,
        isComplete: this.step === 2 && this.questionsList.length === 0 ? false : res,
      };
      const updatesuccess = await this.updateCompletedSteps(payload);
      let _this = this;
      // Common func
      function proceedNext() {
        const goTo = routePaths[targetRoute === null ? _this.step + 1 : targetRoute];
        _this.$router.push({
          name: "ApplicationInfo",
          params: {
            applicationID: _this.$route.params.applicationID,
            step: goTo,
          },
        });
      }
      if (updatesuccess && redirect) {
        this.setDirtyData(false);
        await this.completedSteps(this.step);
        let proceedToNext = true;
        bus.$emit("showFullpageOverlay", {
          showLayover: false,
        });
        // Allow to last step only when the remaining steps are completed if this is the previous step
        if (this.isMainLocation && this.step === this.steps.length - 2) {
          if (targetRoute === null && !this.allStepsCompleted) proceedToNext = false;
        }
        proceedToNext && proceedNext();
      }
    },
    prevButtonClick() {
      this.$router.push({
        name: "ApplicationInfo",
        params: {
          applicationID: this.$route.params.applicationID,
          step: routePaths[this.step - 1],
        },
      });
    },
    getSubQuestions(ques) {
      const dropOptions = ques.selections;
      // applicationTypeID = 1 is Regular Question
      // applicationTypeID = 2 is Renewal Question
      if (
        [2, 4, 5, 8, 9].includes(ques.appQuestionInputTypeID) &&
        dropOptions &&
        dropOptions.length &&
        (ques.applicationTypeID === 1 || ques.applicationTypeID === this.applicationStatusTypeId)
      ) {
        const child_ques = this.questionsList.filter((item) =>
          dropOptions.some(
            (item2) =>
              item2.childQuestion &&
              item2.childQuestion.split(",").includes(item.questionID.toString())
          )
        );
        if (child_ques.length) {
          return child_ques;
        }
        return null;
      }
      return null;
    },
  },
};
</script>
<style>
.form-element {
  display: flex;
  justify-content: center;
}
</style>
