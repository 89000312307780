<template>
  <b-form-select
    class="form-select"
    v-model.trim="v.questionsForm[`question_${questionID}`].$model"
    :state="validateField(`question_${questionID}`)"
    :options="selections"
    :disabled="disabled"
    value-field="value"
    text-field="text"
  ></b-form-select>
</template>
<script>
export default {
  props: ["questionID", "validateField", "v", "selections", "disabled"],
};
</script>
