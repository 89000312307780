<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      locationTypes: [],
    };
  },
  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
    ]),
    async getAllLocationTypes() {
      await axios
        .get(`${WebAPIUrl}/api/location-type`)
        .then((result) => {
          this.locationTypes = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getLocationTypesByProgramIDs(programIDs) {
      // console.log(programIDs);
      await axios
        .get(`${WebAPIUrl}/api/location-type/by-program/${programIDs}`)
        .then((result) => {
          this.locationTypes = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
  },
};
</script>
