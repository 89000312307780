<template>
  <div>
    <p>
      Tell us about any relationships that may trigger discounts for your organization. Affiliations
      are not required for accreditation/certification. Discounts are applied to the remaining
      balance after the non-refundable deposit is paid. An ACHC representative will reach out to you
      after submitting the application to discuss available affiliate and discount options that best
      suit your organization’s business needs.
    </p>
    <b-row>
      <achc-collapsible-card :card-visible="false" from="application-affiliates">
        <template slot="header">
          <h4>
            Is your organization using consultant services for support in achieving
            accreditation/certification?
          </h4>
        </template>
        <template slot="body">
          <p>
            ACHC Certified Consultants have chosen to participate in a comprehensive training course
            addressing the ACHC process, standards, and survey approach, and have successfully
            completed a competency assessment. ACHC provides the list of Certified Consultants for
            informational purposes only. Working with a consultant does not guarantee accreditation
            and organizations are responsible for their own due diligence.
          </p>
        </template>
      </achc-collapsible-card>
    </b-row>
    <br />
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-select
            :disabled="disableField"
            class="form-select"
            v-model="accreditationAndCertification"
            :state="
              $v.accreditationAndCertification.$dirty
                ? !$v.accreditationAndCertification.$error
                : null
            "
            :options="yesNo"
            text-field="text"
            value-field="value"
            @input="$v.accreditationAndCertification.$touch"
          ></b-form-select>
          <div
            class="text-danger"
            v-if="
              !$v.accreditationAndCertification.required && $v.accreditationAndCertification.$dirty
            "
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col v-if="accreditationAndCertification">
        <b-form-group>
          <LabelWithInfoTootlip
            label="Consultants"
            tooltipText="Please select all that apply to determine best available discount."
          />
          <multiselect
            :disabled="disableField"
            v-model="certifiedConsultants"
            :state="$v.certifiedConsultants.$dirty ? !$v.certifiedConsultants.$error : null"
            :options="affiliateTypesId_2"
            :multiple="true"
            :close-on-select="false"
            placeholder="Consultants"
            track-by="affiliateID"
            label="companyName"
            @input="$v.certifiedConsultants.$touch"
          ></multiselect>
          <div
            class="text-danger"
            v-if="!$v.certifiedConsultants.required && $v.certifiedConsultants.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="showCertifiedConsultantsOthers">
        <b-form-group label="Other Consultant">
          <b-form-input
            :disabled="disableField"
            type="text"
            v-model="certifiedConsultantsOthers"
            :state="
              $v.certifiedConsultantsOthers.$dirty ? !$v.certifiedConsultantsOthers.$error : null
            "
            @input="$v.certifiedConsultantsOthers.$touch"
          />
          <div
            class="text-danger"
            v-if="!$v.certifiedConsultantsOthers.required && $v.certifiedConsultantsOthers.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Aff 3 -->
    <b-row>
      <achc-collapsible-card :card-visible="false" from="application-affiliates">
        <template slot="header">
          <h4>Is your organization affiliated with an ACHC Strategic Partner?</h4>
        </template>
        <template slot="body">
          <p>
            These organizations, associations, franchises, and vendors have chosen to partner with
            ACHC to offer their members and customers relevant education materials and accreditation
            discounts.
          </p>
        </template>
      </achc-collapsible-card>
    </b-row>
    <br />
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-select
            :disabled="disableField"
            class="form-select"
            v-model="strategicPartner"
            :state="$v.strategicPartner.$dirty ? !$v.strategicPartner.$error : null"
            :options="yesNo"
            text-field="text"
            value-field="value"
            @input="$v.strategicPartner.$touch"
          ></b-form-select>
          <div
            class="text-danger"
            v-if="!$v.strategicPartner.required && $v.strategicPartner.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col v-if="strategicPartner">
        <b-form-group>
          <LabelWithInfoTootlip
            label="Strategic Partners"
            tooltipText="Please select all that apply to determine best available discount."
          />
          <multiselect
            :disabled="disableField"
            v-model="achcStrategicPartner"
            :state="$v.achcStrategicPartner.$dirty ? !$v.achcStrategicPartner.$error : null"
            :options="affiliateTypesId_3"
            :multiple="true"
            :close-on-select="false"
            placeholder="Strategic Partners"
            track-by="affiliateID"
            label="companyName"
            @input="$v.achcStrategicPartner.$touch"
          ></multiselect>
          <div
            class="text-danger"
            v-if="!$v.achcStrategicPartner.required && $v.achcStrategicPartner.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="showAchcStrategicPartnerOthers">
        <b-form-group label="Other Strategic Partner">
          <b-form-input
            :disabled="disableField"
            type="text"
            v-model="achcStrategicPartnerOthers"
            :state="
              $v.achcStrategicPartnerOthers.$dirty ? !$v.achcStrategicPartnerOthers.$error : null
            "
            @input="$v.achcStrategicPartnerOthers.$touch"
          />
          <div
            class="text-danger"
            v-if="!$v.achcStrategicPartnerOthers.required && $v.achcStrategicPartnerOthers.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Aff 3 end -->
    <!-- Aff 4 start -->
    <b-row class="mb-4">
      <achc-collapsible-card :card-visible="false" from="application-affiliates">
        <template slot="header">
          <h4>Is your organization owned (entirely or in part) by another Legal Entity?</h4>
        </template>
        <template slot="body">
          <p>
            This may be a separate or partial legal owner (e.g., hospital system and joint venture,
            physician-owned hospital).
          </p>
        </template>
      </achc-collapsible-card>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-select
            :disabled="disableField"
            class="form-select"
            v-model="legalEntity"
            :state="$v.legalEntity.$dirty ? !$v.legalEntity.$error : null"
            :options="yesNo"
            text-field="text"
            value-field="value"
            @input="$v.legalEntity.$touch"
          ></b-form-select>
          <div class="text-danger" v-if="!$v.legalEntity.required && $v.legalEntity.$dirty">
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col v-if="legalEntity">
        <b-form-group>
          <LabelWithInfoTootlip
            label="Legal Entities"
            tooltipText=" Please select all that apply."
          />
          <multiselect
            :disabled="disableField"
            v-model="legalEntities"
            :state="$v.legalEntities.$dirty ? !$v.legalEntities.$error : null"
            :options="affiliateTypesId_4"
            :multiple="true"
            :close-on-select="false"
            placeholder="Legal Entities"
            track-by="affiliateID"
            label="companyName"
            @input="$v.legalEntities.$touch"
          ></multiselect>
          <div class="text-danger" v-if="!$v.legalEntities.required && $v.legalEntities.$dirty">
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="showLegalEntitiesOthers">
        <b-form-group label="Other Legal Entity">
          <b-form-input
            :disabled="disableField"
            type="text"
            v-model="legalEntitiesOthers"
            :state="$v.legalEntitiesOthers.$dirty ? !$v.legalEntitiesOthers.$error : null"
            @input="$v.legalEntitiesOthers.$touch"
          />
          <div
            class="text-danger"
            v-if="!$v.legalEntitiesOthers.required && $v.legalEntitiesOthers.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Aff 4 end -->
    <!-- Aff 5 Start -->
    <b-row class="mb-4">
      <achc-collapsible-card :card-visible="false" from="application-affiliates">
        <template slot="header">
          <h4>Is your organization managed by a Contractual Agreement?</h4>
        </template>
        <template slot="body">
          <p>This may be a contractual agreement with a management service.</p>
        </template>
      </achc-collapsible-card>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-select
            :disabled="disableField"
            class="form-select"
            v-model="contractualAgreement"
            :state="$v.contractualAgreement.$dirty ? !$v.contractualAgreement.$error : null"
            :options="yesNo"
            text-field="text"
            value-field="value"
            @input="$v.contractualAgreement.$touch"
          ></b-form-select>
          <div
            class="text-danger"
            v-if="!$v.contractualAgreement.required && $v.contractualAgreement.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col v-if="contractualAgreement">
        <b-form-group>
          <LabelWithInfoTootlip
            label="Contractual Agreements"
            tooltipText=" Please select all that apply."
          />
          <multiselect
            :disabled="disableField"
            v-model="contractualAgreements"
            :state="$v.contractualAgreements.$dirty ? !$v.contractualAgreements.$error : null"
            :options="affiliateTypesId_5"
            :multiple="true"
            :close-on-select="false"
            placeholder="Contractual Agreements"
            track-by="affiliateID"
            label="companyName"
            @input="$v.contractualAgreements.$touch"
          ></multiselect>
          <div
            class="text-danger"
            v-if="!$v.contractualAgreements.required && $v.contractualAgreements.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="showContractualAgreementsOthers">
        <b-form-group label="Other Contractual Agreement">
          <b-form-input
            :disabled="disableField"
            type="text"
            v-model="contractualAgreementsOthers"
            :state="
              $v.contractualAgreementsOthers.$dirty ? !$v.contractualAgreementsOthers.$error : null
            "
            @input="$v.contractualAgreementsOthers.$touch"
          />
          <div
            class="text-danger"
            v-if="!$v.contractualAgreementsOthers.required && $v.contractualAgreementsOthers.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <!--  Aff 5 End-->
    <!-- Aff 6 Start -->
    <b-row>
      <achc-collapsible-card :card-visible="false" from="application-affiliates">
        <template slot="header">
          <h4>Did your organization obtain Policies and Procedures (P&Ps) from a vendor?</h4>
        </template>
        <template slot="body">
          <p>
            Many ACHC standards are based on written policies and procedures. While all P&Ps will be
            reviewed for specific relevance to your organization’s operations, some vendors have
            sought ACHC approval of their P&P templates designed to align with ACHC standards.
          </p>
        </template>
      </achc-collapsible-card>
    </b-row>
    <br />
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-select
            :disabled="disableField"
            class="form-select"
            v-model="policiesAndProcedure"
            :state="$v.policiesAndProcedure.$dirty ? !$v.policiesAndProcedure.$error : null"
            :options="yesNo"
            text-field="text"
            value-field="value"
            @input="$v.policiesAndProcedure.$touch"
          ></b-form-select>
          <div
            class="text-danger"
            v-if="!$v.policiesAndProcedure.required && $v.policiesAndProcedure.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col v-if="policiesAndProcedure">
        <b-form-group>
          <LabelWithInfoTootlip label="P&P Vendors" tooltipText=" Please select all that apply." />
          <multiselect
            :disabled="disableField"
            v-model="policiesAndProcedures"
            :state="$v.policiesAndProcedures.$dirty ? !$v.policiesAndProcedures.$error : null"
            :options="affiliateTypesId_6"
            :multiple="true"
            :close-on-select="false"
            placeholder="P&P Vendors"
            track-by="affiliateID"
            label="companyName"
            @input="$v.policiesAndProcedures.$touch"
          ></multiselect>
          <div
            class="text-danger"
            v-if="!$v.policiesAndProcedures.required && $v.policiesAndProcedures.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="showPoliciesAndProceduresOthers">
        <b-form-group label="Other P&P Vendor">
          <b-form-input
            :disabled="disableField"
            type="text"
            v-model="policiesAndProceduresOthers"
            :state="
              $v.policiesAndProceduresOthers.$dirty ? !$v.policiesAndProceduresOthers.$error : null
            "
            @input="$v.policiesAndProceduresOthers.$touch"
          />
          <div
            class="text-danger"
            v-if="!$v.policiesAndProceduresOthers.required && $v.policiesAndProceduresOthers.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <!--  Aff 6 End-->
    <!-- -->
    <b-row class="mb-4">
      <achc-collapsible-card :card-visible="false" from="application-affiliates">
        <template slot="header">
          <h4>Has a member of your organization attended an ACHCU Workshop in the past year?</h4>
        </template>
        <template slot="body">
          <p>
            ACHCU is a separate entity that provides education resources supporting ACHC programs.
            Participation in some workshops confers discounts on accreditation/certification.
            <strong
              >Workshop Attendance records will be checked prior to applying discounts.</strong
            >
          </p>
        </template>
      </achc-collapsible-card>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-select
            :disabled="disableField"
            class="form-select"
            v-model="workshopAttendance"
            :state="$v.workshopAttendance.$dirty ? !$v.workshopAttendance.$error : null"
            :options="yesNo"
            text-field="text"
            value-field="value"
            @input="$v.workshopAttendance.$touch"
          ></b-form-select>
          <div
            class="text-danger"
            v-if="!$v.workshopAttendance.required && $v.workshopAttendance.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="workshopAttendance">
        <b-form-group label="Date Attended">
          <b-form-input
            :disabled="disableField"
            type="date"
            v-model="workShopAttendedDate"
            :state="$v.workShopAttendedDate.$dirty ? !$v.workShopAttendedDate.$error : null"
            @input="$v.workShopAttendedDate.$touch"
          />
          <div
            class="text-danger"
            v-if="!$v.workShopAttendedDate.required && $v.workShopAttendedDate.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="workshopAttendance">
        <b-form-group label="Program(s)">
          <b-form-select
            :disabled="disableField"
            class="form-select"
            v-model="workShopAttendedProgramID"
            :state="
              $v.workShopAttendedProgramID.$dirty ? !$v.workShopAttendedProgramID.$error : null
            "
            :options="groupedPrograms"
            text-field="programDescription"
            value-field="programID"
            @input="$v.workShopAttendedProgramID.$touch"
          ></b-form-select>
          <div
            class="text-danger"
            v-if="!$v.workShopAttendedProgramID.required && $v.workShopAttendedProgramID.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <!--  -->
  </div>
</template>
<script>
import CollapsibleCard from "../../../components/Common/CollapsibleCard.vue";
import AffiliateMixin from "../../../mixins/AffiliateMixin.vue";
import CompanyAffiliateMixin from "../../../mixins/CompanyAffiliateMixin.vue";
import ProgramMixin from "../../../mixins/ProgramMixin.vue";
import applicationCompanyMixin from "../../../mixins/applicationCompanyMixin.vue";
import LabelWithInfoTootlip from "../../../components/Common/LabelWithInfoTooltip.vue";
import Multiselect from "vue-multiselect";
import { validationMixin } from "vuelidate";
const { required, requiredIf } = require("vuelidate/lib/validators");
import DirtyDataMixin from "../../../mixins/DirtyDataMixin.vue";
import { bus } from "../../../main";
export default {
  components: {
    "achc-collapsible-card": CollapsibleCard,
    Multiselect,
    LabelWithInfoTootlip,
  },
  mixins: [
    AffiliateMixin,
    ProgramMixin,
    validationMixin,
    CompanyAffiliateMixin,
    applicationCompanyMixin,
    DirtyDataMixin,
  ],
  validations: {
    accreditationAndCertification: {
      required,
    },
    strategicPartner: {
      required,
    },
    legalEntity: {
      required,
    },
    policiesAndProcedure: {
      required,
    },
    contractualAgreement: {
      required,
    },
    workshopAttendance: {
      required,
    },
    workShopAttendedProgramID: {
      required: requiredIf(function () {
        return this.workshopAttendance;
      }),
    },
    workShopAttendedDate: {
      required: requiredIf(function () {
        return this.workshopAttendance;
      }),
    },
    certifiedConsultants: {
      required: requiredIf(function () {
        return this.accreditationAndCertification;
      }),
    },
    achcStrategicPartner: {
      required: requiredIf(function () {
        return this.strategicPartner;
      }),
    },
    legalEntities: {
      required: requiredIf(function () {
        return this.legalEntity;
      }),
    },
    contractualAgreements: {
      required: requiredIf(function () {
        return this.contractualAgreement;
      }),
    },
    policiesAndProcedures: {
      required: requiredIf(function () {
        return this.policiesAndProcedure;
      }),
    },
    certifiedConsultantsOthers: {
      required: requiredIf(function () {
        return this.showCertifiedConsultantsOthers;
      }),
    },
    achcStrategicPartnerOthers: {
      required: requiredIf(function () {
        return this.showAchcStrategicPartnerOthers;
      }),
    },
    legalEntitiesOthers: {
      required: requiredIf(function () {
        return this.showLegalEntitiesOthers;
      }),
    },
    contractualAgreementsOthers: {
      required: requiredIf(function () {
        return this.showContractualAgreementsOthers;
      }),
    },
    policiesAndProceduresOthers: {
      required: requiredIf(function () {
        return this.showPoliciesAndProceduresOthers;
      }),
    },
  },
  async created() {
    this.companyId = this.$route.params.companyID;
    const { applicationID } = this.$route.params;
    await this.getCompanyAffiliates(this.companyId);
    await this.getAllProgramsByType();
    await this.getWorkshopAttendedDate(applicationID);
    await this.getApplicationCompanyDetails(applicationID, this.companyId);
    const { applicationCompanyID } = this.applicationCompanyDetail;
    this.applicationCompanyID = applicationCompanyID;
    await this.getApplicationCompanyDetailsForOthers(applicationCompanyID);
    const {
      otherCertifiedConsultants,
      otherStrategicPartner,
      otherLegalEntity,
      otherContractualAgreement,
      otherPoliciesandProcedures,
    } = this.applicationCompanyDetailForOther;
    this.affiliateTypesId_2 = await this.getAffiliateTypesByAffiliateIds(2);
    this.affiliateTypesId_2 &&
      this.affiliateTypesId_2.push({
        affiliateID: -1,
        companyName: "Other",
      });
    this.affiliateTypesId_3 = await this.getAffiliateTypesByAffiliateIds(3);
    this.affiliateTypesId_3.push({
      affiliateID: -1,
      companyName: "Other",
    });
    this.affiliateTypesId_4 = await this.getAffiliateTypesByAffiliateIds(4);
    this.affiliateTypesId_4.push({
      affiliateID: -1,
      companyName: "Other",
    });
    this.affiliateTypesId_5 = await this.getAffiliateTypesByAffiliateIds(5);
    this.affiliateTypesId_5.push({
      affiliateID: -1,
      companyName: "Other",
    });
    this.affiliateTypesId_6 = await this.getAffiliateTypesByAffiliateIds(6);
    this.affiliateTypesId_6.push({
      affiliateID: -1,
      companyName: "Other",
    });

    this.certifiedConsultantsList = this.companyAffiliates.filter((companyAffiliate) => {
      return companyAffiliate.types.some((type) => type.affiliateTypeID === 2);
    });
    if (otherCertifiedConsultants) {
      this.certifiedConsultantsOthers = otherCertifiedConsultants;
      this.certifiedConsultantsList.push({
        affiliateID: -1,
        companyName: "Other",
      });
    }
    this.achcStrategicPartnerList = this.companyAffiliates.filter((companyAffiliate) => {
      return companyAffiliate.types.some((type) => type.affiliateTypeID === 3);
    });
    if (otherStrategicPartner) {
      this.achcStrategicPartnerOthers = otherStrategicPartner;
      this.achcStrategicPartnerList.push({
        affiliateID: -1,
        companyName: "Other",
      });
    }
    this.legalEntitiesList = this.companyAffiliates.filter((companyAffiliate) => {
      return companyAffiliate.types.some((type) => type.affiliateTypeID === 4);
    });
    if (otherLegalEntity) {
      this.legalEntitiesOthers = otherLegalEntity;
      this.legalEntitiesList.push({
        affiliateID: -1,
        companyName: "Other",
      });
    }
    this.contractualAgreementsList = this.companyAffiliates.filter((companyAffiliate) => {
      return companyAffiliate.types.some((type) => type.affiliateTypeID === 5);
    });
    if (otherContractualAgreement) {
      this.contractualAgreementsOthers = otherContractualAgreement;
      this.contractualAgreementsList.push({
        affiliateID: -1,
        companyName: "Other",
      });
    }
    this.policiesAndProceduresList = this.companyAffiliates.filter((companyAffiliate) => {
      return companyAffiliate.types.some((type) => type.affiliateTypeID === 6);
    });
    if (otherPoliciesandProcedures) {
      this.policiesAndProceduresOthers = otherPoliciesandProcedures;
      this.policiesAndProceduresList.push({
        affiliateID: -1,
        companyName: "Other",
      });
    }
    this.accreditationAndCertification = !!this.certifiedConsultantsList.length;
    this.strategicPartner = !!this.achcStrategicPartnerList.length;
    this.legalEntity = !!this.legalEntitiesList.length;
    this.contractualAgreement = !!this.contractualAgreementsList.length;
    this.policiesAndProcedure = !!this.policiesAndProceduresList.length;
    this.workshopAttendance = !!this.workShopAttendedDate;
    this.initDirtyDataWatcher("accreditationAndCertification");
    this.initDirtyDataWatcher("strategicPartner");
    this.initDirtyDataWatcher("legalEntity");
    this.initDirtyDataWatcher("policiesAndProcedure");
    this.initDirtyDataWatcher("contractualAgreement");
    this.initDirtyDataWatcher("workshopAttendance");
    this.initDirtyDataWatcher("workShopAttendedProgramID");
    this.initDirtyDataWatcher("policiesAndProceduresOthers");
    this.initDirtyDataWatcher("legalEntitiesOthers");
    this.initDirtyDataWatcher("contractualAgreementsOthers");
    this.initDirtyDataWatcher("achcStrategicPartnerOthers");
    this.initDirtyDataWatcher("certifiedConsultantsOthers");
    this.initDirtyDataWatcher("certifiedConsultants");
    this.initDirtyDataWatcher("achcStrategicPartner");
    this.initDirtyDataWatcher("legalEntities");
    this.initDirtyDataWatcher("contractualAgreements");
    this.initDirtyDataWatcher("policiesAndProcedures");
  },
  mounted() {
    bus.$on("applicationStatus", async (data) => {
      this.disableField = data;
    });
    this.$v.$touch();
  },
  beforeDestroy() {
    bus.$off("applicationStatus");
  },
  data() {
    return {
      companyId: 0,
      applicationCompanyID: this.applicationCompanyID,
      workshopAttendance: undefined,
      monthAndYearAttended: undefined,
      certifiedConsultantsOthers: null,
      achcStrategicPartnerOthers: null,
      contractualAgreementsOthers: null,
      legalEntitiesOthers: null,
      policiesAndProceduresOthers: null,
      yesNo: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      affiliateTypesId_2: [],
      affiliateTypesId_3: [],
      affiliateTypesId_4: [],
      affiliateTypesId_5: [],
      affiliateTypesId_6: [],
      //selectedPrograms: [],
      certifiedConsultantsList: [],
      achcStrategicPartnerList: [],
      legalEntitiesList: [],
      contractualAgreementsList: [],
      policiesAndProceduresList: [],
      accreditationAndCertification: false,
      strategicPartner: false,
      legalEntity: false,
      contractualAgreement: false,
      policiesAndProcedure: false,
      applicationStatus: 0,
      disableField: false,
    };
  },
  computed: {
    certifiedConsultants: {
      get() {
        return this.certifiedConsultantsList;
      },
      set(value) {
        this.certifiedConsultantsList = value;
      },
    },
    achcStrategicPartner: {
      get() {
        return this.achcStrategicPartnerList;
      },
      set(value) {
        this.achcStrategicPartnerList = value;
      },
    },
    legalEntities: {
      get() {
        return this.legalEntitiesList;
      },
      set(value) {
        this.legalEntitiesList = value;
      },
    },
    contractualAgreements: {
      get() {
        return this.contractualAgreementsList;
      },
      set(value) {
        this.contractualAgreementsList = value;
      },
    },
    policiesAndProcedures: {
      get() {
        return this.policiesAndProceduresList;
      },
      set(value) {
        this.policiesAndProceduresList = value;
      },
    },
    showCertifiedConsultantsOthers() {
      return (
        this.accreditationAndCertification &&
        this.certifiedConsultants.length &&
        this.certifiedConsultants.find(({ affiliateID }) => affiliateID === -1)
      );
    },
    showAchcStrategicPartnerOthers() {
      return (
        this.strategicPartner &&
        this.achcStrategicPartner.length &&
        this.achcStrategicPartner.find(({ affiliateID }) => affiliateID === -1)
      );
    },
    showLegalEntitiesOthers() {
      return (
        this.legalEntity &&
        this.legalEntities.length &&
        this.legalEntities.find(({ affiliateID }) => affiliateID === -1)
      );
    },
    showContractualAgreementsOthers() {
      return (
        this.contractualAgreement &&
        this.contractualAgreements.length &&
        this.contractualAgreements.find(({ affiliateID }) => affiliateID === -1)
      );
    },
    showPoliciesAndProceduresOthers() {
      return (
        this.policiesAndProcedure &&
        this.policiesAndProcedures.length &&
        this.policiesAndProcedures.find(({ affiliateID }) => affiliateID === -1)
      );
    },
  },
  methods: {
    async saveCompanyAffiliates() {
      let allCompleted = false;

      const companyAffiliateList = [
        ...this.certifiedConsultants,
        ...this.achcStrategicPartner,
        ...this.legalEntities,
        ...this.contractualAgreements,
        ...this.policiesAndProcedures,
      ].reduce((filtered, affiliate) => {
        if (affiliate.affiliateID > -1) {
          const modifiedAffiliate = {
            affiliateID: affiliate.affiliateID,
            affiliateTypeID: affiliate.affiliateTypeID,
          };
          filtered.push(modifiedAffiliate);
        }
        return filtered;
      }, []);
      const saveAffiliate = await this.updateCompanyAffiliate({
        companyID: this.companyId,
        affiliateIDs: companyAffiliateList,
      });
      const saveOtherAffiliate = await this.postAllOthersAffiliates({
        applicationCompanyID: this.applicationCompanyID,

        otherCertifiedConsultants: this.showCertifiedConsultantsOthers
          ? this.certifiedConsultantsOthers
          : null,

        otherStrategicPartner: this.showAchcStrategicPartnerOthers
          ? this.achcStrategicPartnerOthers
          : null,

        otherLegalEntity: this.showLegalEntitiesOthers ? this.legalEntitiesOthers : null,

        otherContractualAgreement: this.showContractualAgreementsOthers
          ? this.contractualAgreementsOthers
          : null,

        otherPoliciesandProcedures: this.showPoliciesAndProceduresOthers
          ? this.policiesAndProceduresOthers
          : null,
      });
      if (saveAffiliate && saveOtherAffiliate) {
        const saveWorkshopAttended = await this.updateWorkshopAttendedDate({
          applicationID: this.$route.params.applicationID,
          workShopAttendedProgramID: this.workshopAttendance
            ? this.workShopAttendedProgramID
            : null,
          workShopAttendedDate: this.workshopAttendance ? this.workShopAttendedDate : null,
        });
        if (saveWorkshopAttended) {
          allCompleted = true;
        }
      }

      this.$v.$touch();
      if (!this.$v.$error) {
        allCompleted = true;
      } else {
        allCompleted = false;
      }
      return allCompleted;
    },
  },
};
</script>

<style lang="scss" scoped></style>
