<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";
import { instance } from "../../src/request/app-axios.js";
export default {
  data() {
    return {
      companyAffiliates: [],
      statusCode: null,
    };
  },
  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "setModalDangerMessage",
      "setModalInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
      "resetMessages",
    ]),
    async getCompanyAffiliates(companyId) {
      await axios
        .get(`${WebAPIUrl}/api/companyaffiliate/${companyId}`)
        .then((result) => {
          this.statusCode = result.status;
          this.companyAffiliates = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async updateCompanyAffiliate(companyAffiliate) {
      return await instance
        .post(`${WebAPIUrl}/api/companyaffiliate`, companyAffiliate)
        .then((result) => {
          if (result.status === 200) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          this.setDangerMessage(error);
          return false;
        });
    },
    async postAllOthersAffiliates(companyAffiliate) {
      return await axios
        .post(`${WebAPIUrl}/api/companyaffiliate/other`, companyAffiliate)
        .then((result) => {
          if (result.status === 200) {
            this.setInfoMessage(result.data);
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          this.setDangerMessage(error);
          return false;
        });
    },
    // async getAffiliateTypesByAffiliateIds(affiliatesTypeIDs) {
    //   return await axios
    //     .get(`${WebAPIUrl}/api/affiliate/affiliate-by-type?affiliatesTypeIDs=${affiliatesTypeIDs}`)
    //     .then((result) => {
    //       return result.data;
    //     })
    //     .catch((error) => {
    //       this.setDangerMessage(error);
    //     });
    // },
  },
};
</script>
