<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      applicationPrograms: [],
      statusCode: null,
    };
  },
  methods: {
    ...mapMutations(["setDangerMessage"]),
    async updateApplicationProgramsOfInterest(applicationCompanyId, programIDs = "") {
      this.processing = true;
      await axios
        .post(
          `${WebAPIUrl}/api/applicationprograms/${applicationCompanyId}?programIDs=${programIDs}`
        )
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) {
            //   this.applicationID = result.data.applicationID;
            //   this.statusCode = result.status;
          }
        })
        .catch((error) => {
          this.messageBox(error, "Error");
        });
      this.processing = false;
    },
  },
};
</script>
