<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";
import { instance } from "../../src/request/app-axios.js";

export default {
  data() {
    return {
      phones: [],
      phoneTypes: [],
      statusCode: null,
      companyPhone: {},
      processing: false,
      applicationSubmitCompanyPhones: [],
    };
  },

  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setModalDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "setModalInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
      "changeCompanyPhone",
    ]),
    async getPhonesForCompany(companyID) {
      await axios
        .get(`${WebAPIUrl}/api/company-phone/${companyID}/table`)
        .then((result) => {
          const { data = [] } = result.data;
          this.changeCompanyPhone(data);
          //this.phones = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getPhoneTypes() {
      await axios
        .get(`${WebAPIUrl}/api/company-phone/phone-type`)
        .then((result) => {
          this.phoneTypes = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async addCompanyPhone(newPhone) {
      var numberWithMask = newPhone.phoneNumber;
      this.processing = true;
      newPhone.phoneNumber = this.removePhoneMask(newPhone.phoneNumber);
      await instance
        .post(`${WebAPIUrl}/api/company-phone`, newPhone)
        .then((result) => {
          this.statusCode = result.status;
          this.setInfoMessage("New phone number " + numberWithMask + " successfully added.");
        })
        .catch((error) => {
          this.setDangerMessage(error);
          throw error;
        });
      this.processing = false;
    },
    async updateCompanyPhone(companyPhone) {
      // Removing the mask here b/c if we remove it directly within the page model, v-mask gets confused.
      var numberWithMask = companyPhone.phoneNumber;
      this.processing = true;
      companyPhone.phoneNumber = this.removePhoneMask(companyPhone.phoneNumber);
      await axios
        .put(`${WebAPIUrl}/api/company-phone/${companyPhone.companyPhoneID}`, companyPhone)
        .then((result) => {
          this.statusCode = result.status;
          this.setInfoMessage("Successfully updated phone number " + numberWithMask);
          // ideally we shouldn't have to do this. But phoneTypeDesc wont update in the UI if we dont requery the API for it
          this.getPhonesForCompany(companyPhone.companyID);
        })
        .catch((error) => {
          this.setDangerMessage(error);
          throw error;
        });
      this.processing = false;
    },
    async deleteCompanyPhone(companyPhoneID) {
      await axios
        .delete(`${WebAPIUrl}/api/company-phone/${companyPhoneID}`)
        .then((result) => {
          this.statusCode = result.status;
          this.appendInfoMessage(result.data);
          // remove the deleted record from our local data
          this.phones.splice(
            this.phones.findIndex(function (phone) {
              return phone.companyPhoneID === companyPhoneID;
            }),
            1
          );
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getCompanyPhoneBycompanyPhoneID(companyPhoneID) {
      await axios
        .get(`${WebAPIUrl}/api/company-phone/${companyPhoneID}`)
        .then((result) => {
          this.companyPhone = result.data;
        })
        .catch((error) => {
          this.setModalDangerMessage(error);
        });
    },
    async getApplicationSubmitPhonesForCompany(applicationCompanyId) {
      await axios
        .get(`${WebAPIUrl}/api/company-phone/by-applicationCompanyID/${applicationCompanyId}`)
        .then((result) => {
          const { data = [] } = result;
          this.applicationSubmitCompanyPhones = data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
  },
};
</script>
