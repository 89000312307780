<template>
  <fieldset class="form-group parent-child-ques-sec">
    <div class="hereone">
      <LabelWithInfoTootlip
        :label="question.questionText"
        :isHoverClickable="true"
        :tooltipText="question.tooltip"
      />
    </div>
    <div>
      <component
        :is="questionTypeAndComp.find(({ id }) => id === question.appQuestionInputTypeID).component"
        :questionID="question.questionID"
        :validateField="validateField"
        :v="vali"
        :type="questionTypeAndComp.find(({ id }) => id === question.appQuestionInputTypeID).type"
        :selections="
          parentQuestionInputTypeId === 9
            ? contactDetails
            : question.questionID === 239
            ? companyPhoneNumbers
            : question.selections
        "
        :disabled="disabled"
      ></component>
      <div
        class="text-danger"
        v-if="
          !vali.questionsForm[`question_${question.questionID}`].required &&
          vali.questionsForm[`question_${question.questionID}`].$dirty
        "
      >
        This field is required.
      </div>
      <div
        class="text-danger"
        v-if="
          !vali.questionsForm[`question_${question.questionID}`].maxLength &&
          vali.questionsForm[`question_${question.questionID}`].$dirty &&
          question.maxValidationLength
        "
      >
        Must have at most
        {{ vali.questionsForm[`question_${question.questionID}`].$params.maxLength.max }}
        digits.
      </div>
      <div
        class="text-danger"
        v-if="
          !vali.questionsForm[`question_${question.questionID}`].minLength &&
          vali.questionsForm[`question_${question.questionID}`].$dirty &&
          question.minValidationLength
        "
      >
        Must have at least
        {{ vali.questionsForm[`question_${question.questionID}`].$params.minLength.min }}
        digits.
      </div>
      <div
        class="text-danger"
        v-if="
          !vali.questionsForm[`question_${question.questionID}`].numeric &&
          question.appQuestionDataTypeID === 1
        "
      >
        Must be a number
      </div>
      <div
        class="text-danger"
        v-if="
          !vali.questionsForm[`question_${question.questionID}`].alphaNum &&
          [2, 3].includes(question.appQuestionDataTypeID) &&
          question.appQuestionInputTypeID !== 5
        "
      >
        Must be a string
      </div>
      <div
        class="text-danger"
        v-if="
          !vali.questionsForm[`question_${question.questionID}`].alpha &&
          question.appQuestionDataTypeID === 4
        "
      >
        Must be Yes / No
      </div>
      <div
        class="text-danger"
        v-if="
          !vali.questionsForm[`question_${question.questionID}`].decimal &&
          question.appQuestionDataTypeID === 5
        "
      >
        Must be a decimal
      </div>
    </div>
  </fieldset>
</template>
<script>
import DropdownQuestion from "../Questions/DropdownQuestion.vue";
import InputQuestion from "../Questions/InputQuestion.vue";
import LabelWithInfoTootlip from "../../../../components/Common/LabelWithInfoTooltip.vue";
import MultiSelectQuestion from "../Questions/MultiSelectQuestion.vue";
import CheckboxQuestion from "../Questions/CheckboxQuestion.vue";
import CheckboxGroupQuestion from "../Questions/CheckboxGroupQuestion.vue";
//import { bus } from "../../../../main";
export default {
  props: [
    "question",
    "validateField",
    "vali",
    "disabled",
    "parentQuestionInputTypeId",
    "contactDetails",
    "companyPhoneNumbers",
    "defaultCompanyPhone",
  ],
  components: {
    LabelWithInfoTootlip,
    DropdownQuestion,
    InputQuestion,
    MultiSelectQuestion,
    CheckboxQuestion,
    CheckboxGroupQuestion,
  },
  data() {
    return {
      //Form Type and Component Configuration based on ID
      questionTypeAndComp: [
        { id: 1, type: "text", component: "InputQuestion" }, //d
        { id: 2, type: "checkbox", component: "CheckboxQuestion" },
        { id: 3, type: "radio", component: "InputQuestion" },
        { id: 4, type: "select", component: "DropdownQuestion" }, //d
        { id: 5, type: "multiselect", component: "MultiSelectQuestion" }, //d
        { id: 6, type: "date", component: "InputQuestion" },
        { id: 7, type: "file", component: "InputQuestion" },
        { id: 8, type: "checkbox-group", component: "CheckboxGroupQuestion" },
        { id: 9, type: "additional", component: "" },
      ],
      disableField: false,
    };
  },
};
</script>
<style scoped>
.parent-child-ques-sec {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
</style>
