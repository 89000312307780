var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"full-width"},[_c('div',{staticClass:"additional-site-grid-header mb-3"},[_c('confirmation',{ref:"Confirmation"}),_c('h1',{staticClass:"bold-header-text app-header"},[_vm._v("Company Phone")]),(_vm.from !== 'main-application-company-phone')?_c('button',{staticClass:"base-button",attrs:{"disabled":_vm.disableField},on:{"click":_vm.openCompanyPhoneModal}},[_vm._v(" Add New ")]):_vm._e()],1),(_vm.from !== 'main-application-company-phone')?_c('p',{staticClass:"base-header-text"},[_vm._v(" All Phones associated with your companies must be included on an application. Click the "),_c('strong',[_vm._v("Add New")]),_vm._v(" button above to get started. ")]):_vm._e(),_c('b-card',{staticClass:"dashboard-card"},[_c('kendo-grid',{ref:"grid",attrs:{"id":"grid1","sortable":true,"sort":_vm.sort,"data-items":_vm.gridData,"selectable":true,"columns":_vm.from === 'main-application-company-phone'
          ? _vm.tableColumns.filter(function (ref) {
                    var title = ref.title;

                    return title !== 'Actions';
})
          : _vm.tableColumns,"resizable":true},on:{"datastatechange":_vm.dataStateChange},scopedSlots:_vm._u([{key:"phoneNumberTemplate",fn:function(ref){
          var props = ref.props;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("VMask")(props.dataItem.phoneNumber,_vm.phoneMask))+" ")])]}},{key:"myTemplate",fn:function(ref){
          var props = ref.props;
return [_c('td',{staticClass:"k-command-cell"},[_c('custom',{attrs:{"data-item":props.dataItem},on:{"edit":_vm.editCompany,"remove":_vm.deleteCompany}})],1)]}}])}),_c('CompanyPhoneModal',{ref:"CompanyPhoneModal"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }