var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalbasic",attrs:{"id":"modalbasic","size":"lg","title":this.title,"hide-footer":true,"no-close-on-backdrop":true},on:{"hide":function($event){return _vm.close()},"ok":function($event){return _vm.close()},"cancel":function($event){return _vm.close()}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
return [_c('b-button',{staticClass:"mr-1",attrs:{"size":"lg","variant":"outline-danger"},on:{"click":ok}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"mr-1",attrs:{"disabled":_vm.$v.$invalid,"size":"lg","variant":"success"},on:{"click":function($event){return _vm.saveCompanyPhone()}}},[_vm._v(" Save ")])]}}]),model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('messages'),_c('b-form',[_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Phone Number:","label-for":"nested-phoneNumber"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phoneMask),expression:"phoneMask"}],staticClass:"col-sm-10",attrs:{"id":"nested-phoneNumber","state":_vm.$v.companyPhone.phoneNumber.$dirty ? !_vm.$v.companyPhone.phoneNumber.$error : null,"placeholder":"Phone Number"},model:{value:(_vm.$v.companyPhone.phoneNumber.$model),callback:function ($$v) {_vm.$set(_vm.$v.companyPhone.phoneNumber, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.companyPhone.phoneNumber.$model"}}),(!_vm.$v.companyPhone.phoneNumber.required && _vm.$v.companyPhone.phoneNumber.$dirty)?_c('div',{staticClass:"text-danger"},[_vm._v(" Field is required ")]):_vm._e(),(!_vm.$v.companyPhone.phoneNumber.minLength)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.companyPhone.phoneNumber.$error}},[_vm._v(" Must have at least 10 digits. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Ext.","label-for":"nested-phoneNumberExtension"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.extMask),expression:"extMask"}],staticClass:"col-sm-10",attrs:{"id":"nested-phoneNumberExtension","state":_vm.$v.companyPhone.phoneNumberExtension.$dirty
                ? !_vm.$v.companyPhone.phoneNumberExtension.$error
                : null,"placeholder":"Ext."},model:{value:(_vm.$v.companyPhone.phoneNumberExtension.$model),callback:function ($$v) {_vm.$set(_vm.$v.companyPhone.phoneNumberExtension, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.companyPhone.phoneNumberExtension.$model"}}),(!_vm.$v.companyPhone.phoneNumberExtension.maxLength)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.companyPhone.phoneNumberExtension.$error}},[_vm._v(" Must have at most "+_vm._s(_vm.$v.companyPhone.phoneNumberExtension.$params.maxLength.max)+" digits. ")]):_vm._e(),(!_vm.$v.companyPhone.phoneNumberExtension.numeric)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.companyPhone.phoneNumberExtension.$error}},[_vm._v(" Must be a number. ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"formrow"},[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Phone Number Type")]),_c('b-form-select',{staticClass:"form-select",attrs:{"state":_vm.$v.companyPhone.phoneNumberType.$dirty
                ? !_vm.$v.companyPhone.phoneNumberType.$error
                : null,"options":_vm.phoneTypes,"value-field":"phoneNumberTypeID","text-field":"phoneNumberTypeDesc"},on:{"input":_vm.$v.companyPhone.$touch},model:{value:(_vm.$v.companyPhone.phoneNumberType.$model),callback:function ($$v) {_vm.$set(_vm.$v.companyPhone.phoneNumberType, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.companyPhone.phoneNumberType.$model"}}),(
              !_vm.$v.companyPhone.phoneNumberType.required && _vm.$v.companyPhone.phoneNumberType.$dirty
            )?_c('div',{staticClass:"text-danger"},[_vm._v(" Field is required ")]):_vm._e()],1)],1)],1),_c('b-row',[_c('div',{staticClass:"loginbtnbg"},[_c('api-button-overlay',{attrs:{"processing":_vm.processing}},[_c('b-button',{staticClass:"login-blue",attrs:{"disabled":_vm.$v.$invalid},on:{"click":function($event){return _vm.saveCompanyPhone()}}},[_c('span',{staticClass:"label"},[_vm._v("Save")])])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }