<template>
  <b-modal
    id="modalbasic"
    size="lg"
    ref="modalbasic"
    @hide="close()"
    v-model="isVisible"
    :title="this.title"
    :hide-footer="true"
    :no-close-on-backdrop="true"
    @ok="close()"
    @cancel="close()"
  >
    <messages />
    <b-form>
      <b-row class="formrow">
        <b-col lg="6">
          <b-form-group label="Phone Number:" label-for="nested-phoneNumber">
            <b-form-input
              v-mask="phoneMask"
              id="nested-phoneNumber"
              class="col-sm-10"
              v-model.trim="$v.companyPhone.phoneNumber.$model"
              :state="
                $v.companyPhone.phoneNumber.$dirty ? !$v.companyPhone.phoneNumber.$error : null
              "
              placeholder="Phone Number"
            ></b-form-input>
            <div
              class="text-danger"
              v-if="!$v.companyPhone.phoneNumber.required && $v.companyPhone.phoneNumber.$dirty"
            >
              Field is required
            </div>
            <div
              class="text-danger"
              :state="!$v.companyPhone.phoneNumber.$error"
              v-if="!$v.companyPhone.phoneNumber.minLength"
            >
              Must have at least 10 digits.
            </div>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group label="Ext." label-for="nested-phoneNumberExtension">
            <b-form-input
              id="nested-phoneNumberExtension"
              v-mask="extMask"
              class="col-sm-10"
              v-model.trim="$v.companyPhone.phoneNumberExtension.$model"
              :state="
                $v.companyPhone.phoneNumberExtension.$dirty
                  ? !$v.companyPhone.phoneNumberExtension.$error
                  : null
              "
              placeholder="Ext."
            ></b-form-input>
            <div
              class="text-danger"
              :state="!$v.companyPhone.phoneNumberExtension.$error"
              v-if="!$v.companyPhone.phoneNumberExtension.maxLength"
            >
              Must have at most
              {{ $v.companyPhone.phoneNumberExtension.$params.maxLength.max }} digits.
            </div>
            <div
              class="text-danger"
              :state="!$v.companyPhone.phoneNumberExtension.$error"
              v-if="!$v.companyPhone.phoneNumberExtension.numeric"
            >
              Must be a number.
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="formrow">
        <b-col lg="12">
          <b-form-group>
            <label>Phone Number Type</label>
            <b-form-select
              class="form-select"
              v-model.trim="$v.companyPhone.phoneNumberType.$model"
              :state="
                $v.companyPhone.phoneNumberType.$dirty
                  ? !$v.companyPhone.phoneNumberType.$error
                  : null
              "
              :options="phoneTypes"
              value-field="phoneNumberTypeID"
              text-field="phoneNumberTypeDesc"
              @input="$v.companyPhone.$touch"
            ></b-form-select>
            <div
              class="text-danger"
              v-if="
                !$v.companyPhone.phoneNumberType.required && $v.companyPhone.phoneNumberType.$dirty
              "
            >
              Field is required
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <div class="loginbtnbg">
          <api-button-overlay :processing="processing">
            <b-button class="login-blue" :disabled="$v.$invalid" @click="saveCompanyPhone()">
              <span class="label">Save</span>
            </b-button>
          </api-button-overlay>
        </div>
      </b-row>
    </b-form>
    <template #modal-footer="{ ok }">
      <b-button size="lg" variant="outline-danger" class="mr-1" @click="ok"> Cancel </b-button>
      <b-button
        :disabled="$v.$invalid"
        size="lg"
        variant="success"
        class="mr-1"
        @click="saveCompanyPhone()"
      >
        Save
      </b-button>
    </template>
  </b-modal>
</template>
<style lang="scss" scoped>
.is-invalid {
  border: 1px solid #dc3545 !important;
}

.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}
</style>
<script>
import CompanyPhoneMixin from "../../mixins/CompanyPhoneMixin.vue";
import { validationMixin } from "vuelidate";
import { mapMutations } from "vuex";
import PhoneMixin from "../../mixins/PhoneMixin.vue";

import ModalMessages from "../../containers/navs/ModalMessages.vue";

const { required, numeric, minLength, maxLength } = require("vuelidate/lib/validators");

export default {
  components: {
    messages: ModalMessages,
  },

  mixins: [validationMixin, CompanyPhoneMixin, PhoneMixin],

  validations: {
    companyPhone: {
      phoneNumber: {
        required,
        minLength: minLength(14),
      },
      phoneNumberType: {
        required,
      },
      phoneNumberExtension: {
        maxLength: maxLength(6),
        numeric,
      },
    },
  },
  data: () => ({
    title: undefined,
    isVisible: false,
    companyPhoneID: 0,
  }),

  async created() {
    await this.getPhoneTypes();
  },

  methods: {
    ...mapMutations(["setModalDangerMessage", "setModalInfoMessage", "resetMessages"]),
    async show(opts = {}) {
      this.title = opts.title;
      this.resetMessages();

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction;
      }
      if (opts.companyID) {
        this.initNewPhone(opts.companyID);
      }
      if (opts.companyPhoneID) {
        this.companyPhoneID = opts.companyPhoneID;
        await this.getCompanyPhoneBycompanyPhoneID(this.companyPhoneID);
      }

      this.isVisible = true;
    },

    close() {
      if (this.exitFunction) {
        this.exitFunction();
      }

      this.companyPhoneID = 0;
      this.setModalDangerMessage("");
      this.setModalInfoMessage("");
      this.isVisible = false;
    },
    async saveCompanyPhone() {
      if (this.companyPhone.phoneNumberExtension === "") {
        this.companyPhone.phoneNumberExtension = null;
      }
      if (this.companyPhoneID) {
        await this.updateCompanyPhone(this.companyPhone);
      } else {
        await this.addCompanyPhone(this.companyPhone);
      }
      this.close();
    },
    initNewPhone(companyID) {
      this.companyPhone = {
        companyID: companyID,
        phoneNumber: "",
        phoneNumberExtension: null,
        phoneNumberType: null,
      };
    },
    validateCompanyInfo(prop) {
      const { $dirty, $error } = this.$v.companyPhone[prop];
      return $dirty ? !$error : null;
    },
  },
};
</script>
