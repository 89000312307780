<template>
  <div class="full-width">
    <section class="form-full-width">
      <div v-if="questionsList.length">
        <b-row class="mb-2">
          <b-col class="col-space" md="12">
            <!-- Loop through program specific  -->
            <details
              open
              v-for="{ programID, programDescription, headers } in programSpecificList"
              :key="programID"
            >
              <summary class="bold-header-text">{{ programDescription }}</summary>
              <!-- Display headers which are related to the program specific   -->
              <div
                v-for="({ description, questions, subHeaders }, index) in questionsList.filter(
                  ({ appQuestionSectionHeaderID }) => headers.includes(appQuestionSectionHeaderID)
                )"
                :key="`header_${index}`"
                class="section-wise-question"
              >
                <!-- Main Header and Its questions  -->
                <h4 v-if="programBasedQuestions(questions, programID).length">{{ description }}</h4>
                <!-- Static description under Statistics for Pharmacy and 'Durable Medical Equipment, Prosthetics, Orthotics, and Supplies' -->
                <div
                  v-if="
                    (programDescription === 'Pharmacy' ||
                      programDescription ===
                        'Durable Medical Equipment, Prosthetics, Orthotics, and Supplies') &&
                    description === 'Statistics'
                  "
                >
                  <LabelWithInfoTootlip
                    label="All DMEPOS suppliers must select all product codes provided by this location or
                                  indicate that no product codes are provided.For Medicare suppliers pc information
                                  is required by CMS and must match the location’s 855S enrollment application.
                                  Product codes are also needed to verify licensure requirements.DMEPOS
                                  applications without a selection, will not be processed.If an expected product
                                  category is not available for selection for this location, it could be because the
                                  correct service that covers the product code was not selected.Please save
                                  application progress and go back to the service selection page of the application
                                  to adjust the services selected."
                  />
                </div>
                <b-row class="mb-2" align-v="stretch" align-content="stretch">
                  <!-- Display questions which are related to the program specific  -->
                  <b-col
                    class="col-space form-element-sec"
                    md="12"
                    v-for="question in programBasedQuestions(questions, programID)"
                    :key="question.questionID"
                  >
                    <!-- Dynamic Question Common Component  -->
                    <!-- Parent Question - Main -->
                    <QuestionForm
                      :question="question"
                      :validateField="validateField"
                      :vali="$v"
                      :disabled="disableField"
                      :parentQuestionInputTypeId="question.appQuestionInputTypeID"
                      :contactDetails="contactDetails"
                      :companyPhoneNumbers="companyPhoneNumbers"
                      :defaultCompanyPhone="defaultCompanyPhone"
                    />
                    <!-- Dynamic Question Common Component  -->

                    <!-- Dynamic Question Common Component  -->
                    <b-row
                      class="child-question"
                      v-if="question.subQuestions && question.subQuestions.length"
                    >
                      <b-row v-for="subQuest in question.subQuestions" :key="subQuest.questionID">
                        <b-col v-if="subQuest && displayQuestion(question, subQuest)">
                          <!-- First level child - Main -->
                          <QuestionForm
                            :question="subQuest"
                            :validateField="validateField"
                            :vali="$v"
                            :disabled="disableField"
                            :parentQuestionInputTypeId="question.appQuestionInputTypeID"
                            :contactDetails="contactDetails"
                            :companyPhoneNumbers="companyPhoneNumbers"
                            :defaultCompanyPhone="defaultCompanyPhone"
                          />
                        </b-col>
                        <!-- Nested Child - Main -->
                        <b-row
                          class="child-question"
                          v-if="
                            subQuest &&
                            displayQuestion(question, subQuest) &&
                            subQuest.subQuestions &&
                            subQuest.subQuestions.length
                          "
                        >
                          <b-row
                            v-for="subChildQuest in subQuest.subQuestions"
                            :key="subChildQuest.questionID"
                          >
                            <b-col v-if="subChildQuest && displayQuestion(subQuest, subChildQuest)">
                              <QuestionForm
                                :question="subChildQuest"
                                :validateField="validateField"
                                :vali="$v"
                                :disabled="disableField"
                                :parentQuestionInputTypeId="subQuest.appQuestionInputTypeID"
                                :contactDetails="contactDetails"
                                :companyPhoneNumbers="companyPhoneNumbers"
                                :defaultCompanyPhone="defaultCompanyPhone"
                              />
                            </b-col>
                          </b-row>
                        </b-row>
                      </b-row>
                    </b-row>

                    <!-- Dynamic Question Common Component  -->
                  </b-col>
                </b-row>
                <!-- Main Header and Its questions  -->
                <!-- Sub Header and Its questions  -->
                <div
                  v-for="(subHead, index2) in subHeaders"
                  :key="`sub_header_${index2}`"
                  class="sub-head"
                >
                  <h6 v-if="programBasedQuestions(subHead.questions, programID).length">
                    {{ subHead.description }}
                  </h6>
                  <!-- Static description under Product Categories for 'Durable Medical Equipment, Prosthetics, Orthotics, and Supplies' -->
                  <div
                    v-if="
                      programDescription ===
                        'Durable Medical Equipment, Prosthetics, Orthotics, and Supplies' &&
                      subHead.description === 'Product Categories'
                    "
                  >
                    <LabelWithInfoTootlip
                      label="The state requires agencies seeking to be a Medicaid Certified Home Health Agency to first become Medicare Certified, which includes following the <a href='https://www.cms.gov/' target='_blank'></LabelWithInfoTootlip>CMS Home Health Conditions of Participation</a>."
                    />
                  </div>
                  <b-row class="mb-2" align-v="stretch" align-content="stretch">
                    <!-- Display questions which are related to the program specific  -->
                    <b-col
                      class="col-space form-element-sec"
                      md="12"
                      v-for="childQuestion in programBasedQuestions(subHead.questions, programID)"
                      :key="childQuestion.questionID"
                    >
                      <!-- Dynamic Question Common Component  -->
                      <!-- Parent Question - SubHeader -->
                      <QuestionForm
                        :question="childQuestion"
                        :validateField="validateField"
                        :vali="$v"
                        :disabled="disableField"
                        :parentQuestionInputTypeId="childQuestion.appQuestionInputTypeID"
                        :contactDetails="contactDetails"
                        :companyPhoneNumbers="companyPhoneNumbers"
                        :defaultCompanyPhone="defaultCompanyPhone"
                      />
                      <!-- Dynamic Question Common Component  -->

                      <!-- Dynamic Question Common Component  -->
                      <b-row
                        v-if="childQuestion.subQuestions && childQuestion.subQuestions.length"
                        class="child-question"
                      >
                        <b-row
                          v-for="subQuest in childQuestion.subQuestions"
                          :key="subQuest.questionID"
                        >
                          <!-- Child Question-Sub Header -->
                          <b-col v-if="subQuest && displayQuestion(childQuestion, subQuest)">
                            <QuestionForm
                              :question="subQuest"
                              :validateField="validateField"
                              :vali="$v"
                              :disabled="disableField"
                              :parentQuestionInputTypeId="childQuestion.appQuestionInputTypeID"
                              :contactDetails="contactDetails"
                              :companyPhoneNumbers="companyPhoneNumbers"
                              :defaultCompanyPhone="defaultCompanyPhone"
                          /></b-col>
                          <!-- Nested Child - Sub Header -->
                          <b-row
                            class="child-question"
                            v-if="
                              subQuest &&
                              displayQuestion(childQuestion, subQuest) &&
                              subQuest.subQuestions &&
                              subQuest.subQuestions.length
                            "
                          >
                            <b-row
                              v-for="subChildQuest in subQuest.subQuestions"
                              :key="subChildQuest.questionID"
                            >
                              <b-col
                                v-if="subChildQuest && displayQuestion(subQuest, subChildQuest)"
                              >
                                <QuestionForm
                                  :question="subChildQuest"
                                  :validateField="validateField"
                                  :vali="$v"
                                  :disabled="disableField"
                                  :parentQuestionInputTypeId="subQuest.appQuestionInputTypeID"
                                  :contactDetails="contactDetails"
                                  :companyPhoneNumbers="companyPhoneNumbers"
                                  :defaultCompanyPhone="defaultCompanyPhone"
                                />
                              </b-col>
                            </b-row>
                          </b-row>
                        </b-row>
                      </b-row>
                      <!-- Dynamic Question Common Component  -->
                    </b-col>
                  </b-row>
                </div>
                <!-- Sub Header and Its questions  -->
              </div>
            </details>
          </b-col>
        </b-row>
      </div>
    </section>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
const {
  maxLength,
  requiredIf,
  minLength,
  alphaNum,
  numeric,
  decimal,
  alpha,
  helpers,
} = require("vuelidate/lib/validators");
import ProgramSpecificMixin from "../../../mixins/ProgramSpecificMixin.vue";
import ApplicationCompanyContactMixin from "../../../mixins/ApplicationCompanyContactMixin.vue";
import CompanyPhoneMixin from "../../../mixins/CompanyPhoneMixin.vue";
import { getApplicationCompanyId } from "../../../utils/customFunctions";
import LabelWithInfoTootlip from "../../../components/Common/LabelWithInfoTooltip.vue";
import QuestionForm from "./Questions/QuestionForm.vue";
import { bus } from "../../../main";
import { mapGetters } from "vuex";

export default {
  components: {
    QuestionForm,
    LabelWithInfoTootlip,
  },
  mixins: [
    validationMixin,
    ProgramSpecificMixin,
    ApplicationCompanyContactMixin,
    CompanyPhoneMixin,
  ],
  props: [
    "questionsList",
    "applicationCompanyID",
    "allQuestions",
    "programSpecificList",
    "childList",
    "companyID",
  ],
  validations() {
    return {
      questionsForm: this.validationRules,
    };
  },
  computed: {
    ...mapGetters({
      getCompanyContact: "getCompanyContact",
      getCompanyPhone: "getCompanyPhone",
    }),
    validationRules() {
      let validationSet = {};
      const dataTypeValidators = [
        { id: 1, label: "numeric", validator: numeric }, //Integer - d
        { id: 2, label: "alphaNum", validator: helpers.regex("alphaNum", /^[a-z0-9_ ]*$/i) }, //String - d
        { id: 3, label: "alphaNum", validator: alphaNum }, //DateTime
        { id: 4, label: "alpha", validator: alpha }, //Boolean Yes/No -d
        { id: 5, label: "decimal", validator: decimal }, //Decimal
      ];
      function formatValidationsSet(list) {
        if (Array.isArray(list)) {
          list.forEach(
            ({
              questionID,
              maxValidationLength,
              minValidationLength,
              inputRequired,
              appQuestionDataTypeID,
              appQuestionInputTypeID,
            }) => {
              let vali = dataTypeValidators.find(({ id }) => id === appQuestionDataTypeID);

              let validations = {
                required: requiredIf(function () {
                  return inputRequired;
                }),
              };
              //Multiselect
              if (appQuestionInputTypeID !== 5 && vali) {
                validations = {
                  ...validations,
                  [vali.label]: vali.validator, //Validator based on Data Type
                };
              }
              if (maxValidationLength) {
                validations = {
                  ...validations,
                  maxLength: maxLength(maxValidationLength),
                };
              }
              if (minValidationLength) {
                validations = {
                  ...validations,
                  minLength: minLength(minValidationLength),
                };
              }
              validationSet[`question_${questionID}`] = validations;
            }
          );
        }
      }
      this.questionsList.forEach((record) => {
        //Main Header Questions
        if (record.questions.length) {
          //Main Questions
          formatValidationsSet(record.questions);
          //Child Questions
          record.questions.forEach((ele) => {
            ele.subQuestions &&
              ele.subQuestions.length &&
              ele.subQuestions.forEach((subEle) => {
                if (this.displayQuestion(ele, subEle)) {
                  formatValidationsSet([subEle]);
                }
                // Nested Child questions validation set
                subEle.subQuestions &&
                  subEle.subQuestions.forEach((subChild) => {
                    if (this.displayQuestion(subEle, subChild)) {
                      formatValidationsSet([subChild]);
                    }
                  });
              });
          });
        }
        //Sub Header Questions
        if (record.subHeaders.length) {
          record.subHeaders.forEach((element) => {
            if (element.questions.length) {
              //Main Questions
              formatValidationsSet(element.questions);
              // Child Questions
              element.questions.forEach((ele) => {
                ele.subQuestions &&
                  ele.subQuestions.length &&
                  ele.subQuestions.forEach((subEle) => {
                    if (this.displayQuestion(ele, subEle)) {
                      formatValidationsSet([subEle]);
                    }
                    // Nested child questions validation set
                    subEle.subQuestions &&
                      subEle.subQuestions.forEach((subChild) => {
                        if (this.displayQuestion(subEle, subChild)) {
                          formatValidationsSet([subChild]);
                        }
                      });
                  });
              });
            }
          });
        }
      });
      return validationSet;
    },
  },
  watch: {
    questionsList(newData) {
      let questionSet = {};
      function formatQuestionsSet(list) {
        list.forEach(({ questionID, answer, appQuestionInputTypeID, selections }) => {
          questionSet[`question_${questionID}`] = answer || "";
          switch (appQuestionInputTypeID) {
            //Dropdown - (Single)
            case 4:
              //Set Default Selection Value
              if (!answer) {
                questionSet[`question_${questionID}`] = selections.length
                  ? selections.find(({ defaultSelection }) => defaultSelection)?.value ?? ""
                  : "";
              }
              break;
            //MultiSelect
            case 5:
              //Set answered options from selections
              questionSet[`question_${questionID}`] =
                answer && selections.length
                  ? selections.filter(({ value }) => answer.split(",").includes(value))
                  : "";
              break;
            case 6:
              //Date Picker
              questionSet[`question_${questionID}`] = answer || null;
              break;
            //Checkbox Group
            case 8:
              questionSet[`question_${questionID}`] = answer || [];
              break;
            default:
              break;
          }
        });
      }
      newData.forEach((record) => {
        //Set Initial Values for questions
        //Main Header
        if (record.questions.length) {
          //Main Questions
          formatQuestionsSet(record.questions);
          //Child Question
          record.questions.forEach((ele) => {
            ele.subQuestions && formatQuestionsSet(ele.subQuestions);
            ele.subQuestions &&
              ele.subQuestions.forEach((ques) => {
                ques.subQuestions && formatQuestionsSet(ques.subQuestions);
              });
          });
        }
        //Sub Header
        if (record.subHeaders.length) {
          record.subHeaders.forEach((element) => {
            if (element.questions.length) {
              //Sub Header Main Questions
              formatQuestionsSet(element.questions);
              //Child Question
              element.questions.forEach((ele) => {
                ele.subQuestions && formatQuestionsSet(ele.subQuestions);
                ele.subQuestions &&
                  ele.subQuestions.forEach((ques) => {
                    ques.subQuestions && formatQuestionsSet(ques.subQuestions);
                  });
              });
            }
          });
        }
      });
      this.questionsForm = questionSet;
    },
    getCompanyContact(newData) {
      const contacts = newData.map((contact, i) => {
        return {
          childQuestion: null,
          defaultSelection: false,
          displayOrder: i + 1,
          text: contact.firstName + " " + contact.lastName,
          value: contact.firstName + " " + contact.lastName,
        };
      });
      this.contactDetails = contacts;
    },
    getCompanyPhone(newData) {
      this.companyPhoneNumbers = newData.map((contact, i) => {
        return {
          childQuestion: null,
          defaultSelection: false,
          displayOrder: i + 1,
          text: this.setPhoneMask(contact.phoneNumber).toString(),
          value: contact.phoneNumber.toString(),
        };
      });
      const defaultNumber = newData.filter(
        (contact) => contact.phoneNumberTypeDesc === "Office-Primary"
      );
      this.defaultCompanyPhone = "";
      if (defaultNumber.length) {
        this.defaultCompanyPhone = defaultNumber[0].phoneNumber.toString();
      }
    },
    questionsForm(newData) {
      for (const key in newData) {
        //  QuestionId 239 default value set
        if (key === "question_239") {
          this.questionsForm[key] = newData[key] || this.defaultCompanyPhone;
        }
      }
    },
  },
  mounted() {
    bus.$on("applicationStatus", async (data) => {
      this.disableField = data;
    });
    this.$v.questionsForm.$touch();
  },
  beforeDestroy() {
    bus.$off("applicationStatus");
  },
  data() {
    return {
      questionsForm: {},
      selected: [], // Must be an array reference!
      options: [
        { text: "Orange", value: "orange" },
        { text: "Apple", value: "apple" },
        { text: "Pineapple", value: "pineapple" },
        { text: "Grape", value: "grape" },
      ],
      status: "not_accepted",
      disableField: false,
      contactDetails: [],
      companyPhoneNumbers: [],
      defaultCompanyPhone: "",
      phoneMask: "(###) ###-####",
    };
  },
  async created() {
    this.getContactDetails();
    await this.getPhonesForCompany(this.companyID);
  },
  methods: {
    async getContactDetails() {
      const ApplicationId = getApplicationCompanyId();
      await this.getApplicationCompanyContactsTable(ApplicationId);
    },
    async saveProgramSpecific() {
      this.setDangerMessage("");
      let companyApplicationQuestions = [];

      for (const key in this.questionsForm) {
        const questionId = parseInt(key.replace("question_", ""));
        let finalAnswer = this.questionsForm[key];

        const ques_type = this.allQuestions.find(
          ({ questionID }) => questionID === questionId
        ).appQuestionInputTypeID;
        //Multi Select Dropdown
        if (ques_type === 5 && this.questionsForm[key]) {
          finalAnswer = this.questionsForm[key].map(({ value }) => value).toString();
          //Child Multiselect Reset
          if (this.childList.includes(questionId.toString())) {
            const parentQuestion = this.allQuestions.find((question) =>
              question.selections.find(
                ({ childQuestion }) =>
                  childQuestion && childQuestion.split(",").includes(questionId.toString())
              )
            );

            if (parentQuestion) {
              const finalValue = parentQuestion.selections.find(
                ({ childQuestion, value }) =>
                  childQuestion &&
                  childQuestion.split(",").includes(questionId.toString()) &&
                  value === this.questionsForm[`question_${parentQuestion.questionID}`]
              );
              if (!finalValue) {
                finalAnswer = "";
              }
            }
          }
        } else {
          if (this.childList.includes(questionId.toString())) {
            const parentQuestion = this.allQuestions.find((item) =>
              item.selections.find(
                ({ childQuestion }) =>
                  childQuestion && childQuestion.split(",").includes(questionId.toString())
              )
            );
            if (parentQuestion && parentQuestion.questionID) {
              const value = this.questionsForm[`question_${parentQuestion.questionID}`];
              const optionVariable = parentQuestion.selections.find(
                ({ childQuestion }) =>
                  childQuestion && childQuestion.split(",").includes(questionId.toString())
              ).value;
              // ques_type 5 child question reset
              if (value && Array.isArray(value)) {
                const finalvalue = value.find(
                  ({ childQuestion }) =>
                    childQuestion && childQuestion.split(",").includes(questionId.toString())
                );
                if (!finalvalue) {
                  finalAnswer = "";
                }
              } else {
                // ques_type 2, 4, 8 reset
                if (value) {
                  const set = value.split(",");
                  if (!set.includes(optionVariable)) {
                    finalAnswer = "";
                  }
                }
              }
            }
          }
        }
        companyApplicationQuestions.push({
          questionID: questionId,
          answer: finalAnswer,
        });
      }
      const payload = {
        applicationCompanyID: this.applicationCompanyID,
        companyApplicationQuestions: companyApplicationQuestions,
      };
      await this.saveApplicationQuestion(payload);
      if (this.statusCode === 200) {
        this.$v.questionsForm.$touch();
        if (!this.$v.questionsForm.$error) {
          return true;
        } else {
          // this.setDangerMessage("Form must be filled out properly.");
          return false;
        }
      }
    },
    validateField(field) {
      const { $dirty, $error } = this.$v.questionsForm[field];
      const returData = $dirty ? !$error : null;
      return returData;
    },
    displayQuestion(parentQuest, childQuest) {
      let show = false;
      //checkbox & select & checkbox-group
      if ([2, 4, 5, 8].includes(parentQuest.appQuestionInputTypeID)) {
        const optionsArray = parentQuest.selections;

        if (optionsArray && optionsArray.length) {
          const childOption = optionsArray.filter(
            (item) =>
              item.childQuestion &&
              item.childQuestion.split(",").includes(childQuest.questionID.toString())
          );
          if (childOption.length) {
            if (parentQuest.appQuestionInputTypeID === 8) {
              show = this.questionsForm[`question_${parentQuest.questionID}`].includes(
                childOption[0].value
              );
            } else if (parentQuest.appQuestionInputTypeID === 5) {
              const value = this.questionsForm[`question_${parentQuest.questionID}`];
              show =
                Array.isArray(value) &&
                value.filter((item) => childOption.some((item2) => item2.value === item.value))
                  .length;
            } else {
              const selectValue = this.questionsForm[`question_${parentQuest.questionID}`];
              show = childOption.filter(({ value }) => value === selectValue).length;
            }
          }
        }
      } else if (parentQuest.appQuestionInputTypeID === 9) {
        show = true;
      }
      return show;
    },
    programBasedQuestions(quesList, prgId) {
      return quesList.filter((rec) => rec.programID === prgId);
    },
    setPhoneMask(phone) {
      if (phone === undefined) {
        return "";
      }
      var x = phone
        .toString()
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
  },
};
</script>
<style scoped>
.full-width {
  width: 100%;
}
.desc-text {
  font-size: 13px;
}
.col-space {
  margin: 5px 0px 5px 0px;
  /* min-height: 170px; */
}
.form-element-sec {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.child-question {
  margin-top: 0.5rem;
}
</style>
