<template>
  <section class="form-full-width company-info-form">
    <h1 class="bold-header-text app-header mb-3" v-if="from === 'company-information'">
      <span v-if="companyInfo.parentCompanyID">Additional Site </span><span v-else>Company</span>
      {{ companyInfo.companyID }}
    </h1>
    <b-row class="mb-2">
      <b-col md="6">
        <b-form-group>
          <LabelWithInfoTootlip
            :label="$t('company.company-legal-name')"
            tooltipText="The legal name of your business must match paperwork sent to your state or federal regulatory bodies."
          />
          <b-form-input
            :disabled="disableField"
            type="text"
            v-model.trim="companyInfo.companyLegalName"
            :state="
              $v.companyInfo.companyLegalName.$dirty
                ? !$v.companyInfo.companyLegalName.$error
                : null
            "
            @input="$v.companyInfo.companyLegalName.$touch"
          />
          <div class="text-danger" v-if="!$v.companyInfo.companyLegalName.maxLength">
            Must be fewer than 75 characters.
          </div>
          <div
            class="text-danger"
            v-if="
              !$v.companyInfo.companyLegalName.required && $v.companyInfo.companyLegalName.$dirty
            "
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <LabelWithInfoTootlip
            :label="$t('company.dba-company-name')"
            tooltipText="The Doing Business As (DBA) name is also referred to as an assumed name, fictitious business name, or trade name."
          />
          <b-form-input
            :disabled="disableField"
            type="text"
            v-model.trim="companyInfo.dbaCompanyName"
            :state="
              $v.companyInfo.dbaCompanyName.$dirty ? !$v.companyInfo.dbaCompanyName.$error : null
            "
            @input="$v.companyInfo.dbaCompanyName.$touch"
          />
          <div class="text-danger" v-if="!$v.companyInfo.dbaCompanyName.maxLength">
            Must be fewer than 75 characters.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col md="6">
        <b-form-group>
          <LabelWithInfoTootlip
            label="Tax ID"
            tooltipText="A Taxpayer Identification Number (TID) is a unique nine-digit number assigned by the Internal Revenue Service (IRS) to business entities operating in the United States and its territories."
          />
          <b-form-input
            :disabled="disableField"
            v-model.trim="companyInfo.maskedFederalIDNumber"
            v-mask="taxMask"
            :state="
              $v.companyInfo.maskedFederalIDNumber.$dirty
                ? !$v.companyInfo.maskedFederalIDNumber.$error
                : null
            "
            @input="$v.companyInfo.maskedFederalIDNumber.$touch"
          />
          <div
            class="text-danger"
            :state="!$v.companyInfo.maskedFederalIDNumber.$error"
            v-if="!$v.companyInfo.maskedFederalIDNumber.minLength"
          >
            Must have at least 9 digits.
          </div>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Organization Website">
          <b-form-input
            :disabled="disableField"
            v-model.trim="companyInfo.website"
            :state="$v.companyInfo.website.$dirty ? !$v.companyInfo.website.$error : null"
            @input="$v.companyInfo.website.$touch"
          />
          <div
            class="text-danger"
            v-if="!$v.companyInfo.website.url && $v.companyInfo.website.$dirty"
          >
            Must be a valid URL.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="3" v-if="from !== 'company-information'">
        <b-form-group label="Incorporation Date">
          <b-form-input
            :disabled="disableField"
            type="date"
            v-model="dateEstablished"
            :state="
              $v.companyInfo.dateEstablished.$dirty ? !$v.companyInfo.dateEstablished.$error : null
            "
            @input="$v.companyInfo.dateEstablished.$touch"
          />
          <div
            class="text-danger"
            v-if="!$v.companyInfo.dateEstablished.required && $v.companyInfo.dateEstablished.$dirty"
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-5" v-if="from === 'company-information'">
      <b-col>
        <b-form-group label="Program(s) of Interest">
          <multiselect
            :disabled="disableField"
            v-model="companyInfo.programsOfInterest"
            :class="{
              'multiselect-is-invalid': $v.companyInfo.programsOfInterest.$dirty
                ? $v.companyInfo.programsOfInterest.$error
                : null,
            }"
            :options="programs"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="$t('dropdown.select-a-program')"
            label="programWebDescription"
            track-by="programInitials"
            :preselect-first="false"
            @input="$v.companyInfo.programsOfInterest.$touch"
          >
            <template slot="selection">
              <span
                class="multiselect__single"
                v-if="$v.companyInfo.programsOfInterest.length && !isOpen"
              >
                {{ $v.companyInfo.programsOfInterest.length }}
                programs selected
              </span>
            </template>
          </multiselect>
          <div
            class="text-danger"
            v-if="
              !$v.companyInfo.programsOfInterest.required &&
              $v.companyInfo.programsOfInterest.$dirty
            "
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="from !== 'company-information'">
      <b-col md="9">
        <b-form-group label="Program(s) of Interest">
          <multiselect
            :disabled="disableField"
            v-model="programsList"
            :class="{
              'multiselect-is-invalid': $v.programsList.$dirty ? $v.programsList.$error : null,
            }"
            :options="programOfInterest"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select Programs..."
            track-by="programID"
            group-values="options"
            group-label="label"
            :preselect-first="false"
            @input="programsUpdated"
            label="programDescription"
            @remove="handleRemovePrograms"
          >
            <template slot="option" slot-scope="props">
              <span
                v-if="props.option.$isLabel"
                class="option__title"
                :title="props.option.$groupLabel"
                >{{ props.option.$groupLabel }}</span
              >
              <span v-else class="option__title" :title="props.option.programDetailDescription">{{
                props.option.programDescription
              }}</span>
            </template>
          </multiselect>
          <div class="text-danger" v-if="!$v.programsList.required && $v.programsList.$dirty">
            This field is required.
          </div>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <a class="plainButton" target="_blank" href="https://navigatordev.achc.org/app/standards"
          >Standards</a
        >
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <b-form-group label="Location Type">
          <multiselect
            :disabled="disableField"
            v-model="companyInfo.locationTypes"
            :class="{
              'multiselect-is-invalid': $v.companyInfo.locationTypes.$dirty
                ? $v.companyInfo.locationTypes.$error
                : null,
            }"
            :options="locationTypes"
            :multiple="true"
            :close-on-select="false"
            :placeholder="$t('dropdown.select-a-location-type')"
            track-by="locationTypeID"
            label="locationTypeDesc"
            group-values="options"
            group-label="label"
            @input="$v.companyInfo.locationTypes.$touch"
          ></multiselect>
          <div class="text-danger mt-1" v-if="validateLocationTypesSelected">
            One location type for each program(s) of interest is required
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col v-if="from === 'company-information'">
        <b-form-group label="Affiliate(s)">
          <multiselect
            :disabled="disableField"
            v-model="companyInfo.affiliates"
            :options="affiliates"
            :multiple="true"
            :close-on-select="false"
            :placeholder="$t('dropdown.select-an-affiliate')"
            track-by="affiliateID"
            label="companyName"
          ></multiselect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="from !== 'company-information'">
      <b-col>
        <b-form-group>
          <LabelWithInfoTootlip
            label="Service(s) of Interest"
            tooltipText="Distinctions require meeting additional standard requirements and incurring added costs. Please reach out to your dedicated account representative for more information."
          />
          <multiselect
            :class="{
              'multiselect-is-invalid': $v.servicesList.$dirty ? $v.servicesList.$error : null,
            }"
            v-model="servicesList"
            :options="servicesMappedToPrograms"
            :disabled="isServiceDropdownDisabled"
            :multiple="true"
            :close-on-select="false"
            placeholder="Select Services..."
            track-by="serviceID"
            group-values="options"
            group-label="label"
            :preselect-first="false"
            label="serviceDescription"
            @input="$v.servicesList.$touch"
          >
            <template slot="option" slot-scope="props">
              <span
                v-if="props.option.$isLabel"
                class="option__title"
                :title="props.option.$groupLabel"
                >{{ props.option.$groupLabel }}</span
              >
              <span v-else class="option__title" :title="props.option.serviceDescription">{{
                props.option.serviceDescription
              }}</span>
            </template>
          </multiselect>
          <div class="text-danger" v-if="!$v.servicesList.required && $v.servicesList.$dirty">
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row style="align-items: end">
      <b-col md="6">
        <b-form-group
          label="Is this location currently accredited by an Accrediting Organization
        (AO), other than ACHC, for the programs and/or services being applied for?"
        ></b-form-group>
        <b-form-select
          :disabled="disableField"
          class="form-select"
          v-model="companyInfo.previouslyAccredited"
          :options="yesNo"
          text-field="text"
          value-field="value"
          :state="
            $v.companyInfo.previouslyAccredited.$dirty
              ? !$v.companyInfo.previouslyAccredited.$error
              : null
          "
          @input="$v.companyInfo.previouslyAccredited.$touch"
        ></b-form-select>
      </b-col>
      <b-col md="6">
        <LabelWithInfoTootlip
          label="
            Total Number of Employees"
          tooltipText="Include all full time and part time employees."
        />
        <b-form-group style="margin-bottom: auto">
          <b-form-input
            :disabled="disableField"
            type="number"
            min="0"
            v-model="applicationCompanyDetail.numberOfEmployees"
            :state="
              $v.applicationCompanyDetail.numberOfEmployees.$dirty
                ? !$v.applicationCompanyDetail.numberOfEmployees.$error
                : null
            "
            @input="$v.applicationCompanyDetail.numberOfEmployees.$touch"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div
          class="text-danger"
          v-if="
            !$v.companyInfo.previouslyAccredited.required &&
            $v.companyInfo.previouslyAccredited.$dirty
          "
        >
          This field is required.
        </div>
      </b-col>
      <b-col>
        <div
          class="text-danger"
          v-if="
            !$v.applicationCompanyDetail.numberOfEmployees.required &&
            $v.applicationCompanyDetail.numberOfEmployees.$dirty
          "
        >
          This field is required.
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group label="Accreditation Organization" v-if="companyInfo.previouslyAccredited">
          <b-form-select
            :disabled="disableField"
            class="form-select"
            v-model="companyInfo.previouslyAccreditedBy"
            :options="accreditationOrganizations"
            text-field="displayName"
            value-field="initialism"
            :state="
              $v.companyInfo.previouslyAccreditedBy.$dirty
                ? !$v.companyInfo.previouslyAccreditedBy.$error
                : null
            "
            @input="$v.companyInfo.previouslyAccreditedBy.$touch"
          />
          <div
            class="text-danger"
            v-if="
              !$v.companyInfo.previouslyAccreditedBy.required &&
              $v.companyInfo.previouslyAccreditedBy.$dirty
            "
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
      <b-col lg="3" v-if="companyInfo.previouslyAccredited">
        <b-form-group label="Accreditation Start Date">
          <b-form-input
            :disabled="disableField"
            type="date"
            v-model="previousAccreditationStart"
            :state="
              $v.companyInfo.previousAccreditationStart.$dirty
                ? !$v.companyInfo.previousAccreditationStart.$error
                : null
            "
            @input="$v.companyInfo.previousAccreditationStart.$touch"
          />
          <div
            class="text-danger"
            v-if="
              !$v.companyInfo.previousAccreditationStart.required &&
              $v.companyInfo.previousAccreditationStart.$dirty
            "
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
      <b-col lg="3" v-if="companyInfo.previouslyAccredited">
        <b-form-group label="Accreditation End Date">
          <b-form-input
            :disabled="disableField"
            type="date"
            v-model="previousAccreditationExpiration"
            :state="
              $v.companyInfo.previousAccreditationExpiration.$dirty
                ? !$v.companyInfo.previousAccreditationExpiration.$error
                : null
            "
            @input="$v.companyInfo.previousAccreditationExpiration.$touch"
          />
          <div
            class="text-danger"
            v-if="
              !$v.companyInfo.previousAccreditationExpiration.required &&
              $v.companyInfo.previousAccreditationExpiration.$dirty
            "
          >
            This field is required.
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="from === 'company-information'">
      <b-col class="save-button-container">
        <api-button-overlay :processing="processing">
          <button class="btn btn-blue" @click="validateAndSaveCompanyInfo" :disabled="formLoading">
            {{ textButton }}
          </button>
        </api-button-overlay>
      </b-col>
    </b-row>
  </section>
</template>
<style lang="scss" scoped>
.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #d02c23 !important;
}
.save-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.company-info-form {
  padding-bottom: 20px;
}
</style>
<script>
import LabelWithInfoTootlip from "../../../components/Common/LabelWithInfoTooltip.vue";
import Multiselect from "vue-multiselect";
import { mapMutations } from "vuex";
import AffiliateMixin from "../../../mixins/AffiliateMixin.vue";
import CompanyMixin from "../../../mixins/CompanyMixin.vue";
import LocationTypeMixin from "../../../mixins/LocationTypeMixin.vue";
import applicationCompanyMixin from "../../../mixins/applicationCompanyMixin.vue";
import CompanyApplicationProgramsMixin from "../../../mixins/CompanyApplicationProgramsMixin.vue";
import ApplicationCompanyServiceMixin from "../../../mixins/ApplicationCompanyServiceMixin.vue";
import AccreditationOrganizationsMixin from "../../../mixins/AccreditationOrganizationMixin.vue";
import ProgramMixin from "../../../mixins/ProgramMixin.vue";
import ServiceMixin from "../../../mixins/ServiceMixin.vue";
import { validationMixin } from "vuelidate";
const { required, minLength, maxLength, helpers, requiredIf } = require("vuelidate/lib/validators");
import moment from "../../../../node_modules/moment/moment";
import DirtyDataMixin from "../../../mixins/DirtyDataMixin.vue";
import { getCurrentCompany } from "../../../utils/customFunctions";
import { bus } from "../../../main";

const url = helpers.regex(
  "url",
  /^((http|ftp|https):\/\/)?(www\.)?([\w-]+\.)+(com\b|edu\b|biz\b|gov\b|in(?:t|fo)\b|mil\b|net\b|org\b|[a-z][a-z]\b)(\/[\w\- ./?]*)?$/
);
export default {
  props: {
    from: {
      type: String,
      default: "company-information",
    },
    parentApplicationCompanyID: {
      type: Number,
    },
  },
  async created() {
    this.formLoading = true;
    const { companyID = 0 } = getCurrentCompany();
    const { applicationID = 0 } = this.$route.params;
    let DynaCompanyID = companyID;
    if (this.from !== "company-information") {
      DynaCompanyID = this.$route.params.companyID;
    }
    await this.getApplicationCompanyDetails(applicationID, DynaCompanyID);
    const { applicationCompanyID } = this.applicationCompanyDetail;
    await this.getCompanyInfo(DynaCompanyID, applicationCompanyID);
    await this.getAllAffiliates();
    await this.getAllActivePrograms();
    this.applicationCompanyID = applicationCompanyID;
    const { parentCompanyID } = this.companyInfo;
    let parentAppCompanyID = this.parentApplicationCompanyID;
    if (!this.parentApplicationCompanyID && parentCompanyID) {
      parentAppCompanyID = await this.getApplicationCompanyDetailsWithReturn(
        applicationID,
        parentCompanyID
      );
    }
    if (!parentCompanyID) {
      await this.getAllProgramsByType();
    } else {
      await this.getAllProgramsByTypeByCompanyId(parentAppCompanyID);
    }

    this.formLoading = false;
    bus.$emit("selectedPrograms", this.applicationPrograms);
    await this.getAllProgramsByTypeV2(this.applicationCompanyID);
    this.programsList = [...this.programsOfInterestByApplicationCompanyId];
    const selectedProgramIDs = this.programsList.map((program) => program.programID).join();
    if (selectedProgramIDs) {
      await this.getServicesMappedToPrograms(selectedProgramIDs);
      await this.getLocationTypesByProgramIDs(selectedProgramIDs);
    }
    await this.getApplicationServices(this.applicationCompanyID);
    this.servicesList = [...this.applicationServices];
    this.from !== "company-information" && this.initDirtyDataWatcher("companyInfo");
    await this.getAllActiveAccreditationOrganizations();

    this.$nextTick(() => {
      this.$v.$reset();
    });
  },
  mounted() {
    bus.$on("applicationStatus", async (data) => {
      this.disableField = data;
    });
    this.$v.companyInfo.$touch();
  },
  data() {
    return {
      formLoading: false,
      taxIdMask: "##-#######",
      programsList: [],
      servicesList: [],
      yesNo: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      applicationStatus: 0,
      disableField: false,
      applicationCompanyID: null,
    };
  },
  mixins: [
    AffiliateMixin,
    CompanyMixin,
    LocationTypeMixin,
    ProgramMixin,
    validationMixin,
    DirtyDataMixin,
    applicationCompanyMixin,
    CompanyApplicationProgramsMixin,
    ServiceMixin,
    ApplicationCompanyServiceMixin,
    AccreditationOrganizationsMixin,
  ],
  validations() {
    return this.validationRules;
  },
  components: {
    Multiselect,
    LabelWithInfoTootlip,
  },
  methods: {
    ...mapMutations(["setPreserveMessageAfterPush"]),
    async validateAndSaveCompanyInfo() {
      if (this.validateCompanyInfoDetails()) {
        this.companyInfo = {
          ...this.companyInfo,
          companyProgramOfInterest: false,
          applicationCompanyID: this.applicationCompanyID,
          isApplicationCompaniesUpdate: false,
        };
        await this.updateCompanyInfo(this.companyInfo);
        this.setDirtyData(false);
        this.setPreserveMessageAfterPush(true);
      }
    },
    async saveCompanyInfoDetails() {
      try {
        this.companyInfo = {
          ...this.companyInfo,
          companyProgramOfInterest: false,
          applicationCompanyID: this.applicationCompanyID,
          isApplicationCompaniesUpdate: false,
        };
        await this.updateCompanyInfo(this.companyInfo);
        await this.updateApplicationCompany(this.applicationCompanyDetail);
        const selectedProgramIDs =
          this.programsList.map((program) => program.programID).join() || "";

        await this.updateApplicationProgramsOfInterest(
          this.applicationCompanyID,
          selectedProgramIDs
        );
        const selectedServiceIDs =
          this.servicesList.map((service) => service.serviceID).join() || "";
        await this.updateApplicationServices(this.applicationCompanyID, selectedServiceIDs);
        this.setPreserveMessageAfterPush(true);
        //this.$router.push(to.fullPath);
        return true;
      } catch (e) {
        return false;
      }
    },
    validateCompanyInfoDetails() {
      this.$v.companyInfo.$touch();
      this.$v.applicationCompanyDetail.numberOfEmployees.$touch();

      if (
        !this.$v.companyInfo.$error &&
        !this.$v.applicationCompanyDetail.numberOfEmployees.$error
      ) {
        if (this.from !== "company-information") {
          this.$v.servicesList.$touch();
          this.$v.programsList.$touch();
          if (!this.$v.programsList.$error && !this.$v.servicesList.$error) {
            return true;
          } else {
            //this.setDangerMessage("Form must be filled out properly.");
            return false;
          }
        }
        return true;
      } else {
        // this.setDangerMessage("Form must be filled out properly.");
        return false;
      }
    },
    //Remove the selected options in the dependent dropdowns (Location Type & Services)
    handleRemovePrograms(option) {
      this.servicesList = this.servicesList.filter(
        ({ programID }) => programID !== option.programID
      );
      this.companyInfo.locationTypes = this.companyInfo.locationTypes.filter(
        ({ programID }) => programID !== option.programID
      );
    },
    async programsUpdated() {
      this.$v.programsList.$touch();
      if (!this.$v.companyInfo.$error) {
        const selectedProgramIDs = this.programsList.map((program) => program.programID).join();
        this.servicesMappedToPrograms = [];
        this.locationTypes = [];
        if (selectedProgramIDs) {
          await this.getServicesMappedToPrograms(selectedProgramIDs);
          await this.getLocationTypesByProgramIDs(selectedProgramIDs);
        }
      } else {
        this.setDangerMessage("Form must be filled out properly.");
      }
    },
    locationTypesAdded() {
      const values = this.companyInfo.locationTypes;
      if (this.locationTypes.length && values && values.length) {
        const selectedTypeIds = values.map(({ locationTypeID }) => locationTypeID);
        const result = this.locationTypes.every(({ options }) =>
          options.some((ele) => selectedTypeIds.includes(ele.locationTypeID))
        );
        return result;
      }
      return false;
    },
  },
  computed: {
    programOfInterest() {
      const { parentCompanyID } = this.companyInfo;
      return !parentCompanyID ? this.groupedPrograms : this.groupedProgramsByCompanyId;
    },
    textButton() {
      return this.from === "company-information" ? "Save" : "Next";
    },
    isServiceDropdownDisabled() {
      return this.programsList === undefined || this.programsList.length === 0 || this.disableField;
    },
    dateEstablished: {
      get: function () {
        if (this.companyInfo.dateEstablished === null) {
          return null;
        }
        return moment(this.companyInfo.dateEstablished).format("yyyy-MM-DD");
      },
      // setter
      set: function (newValue) {
        this.companyInfo.dateEstablished = newValue || null;
      },
    },
    previousAccreditationExpiration: {
      get: function () {
        if (this.companyInfo.previousAccreditationExpiration === null) {
          return null;
        }
        return moment(this.companyInfo.previousAccreditationExpiration).format("yyyy-MM-DD");
      },
      // setter
      set: function (newValue) {
        this.companyInfo.previousAccreditationExpiration = newValue;
      },
    },
    previousAccreditationStart: {
      get: function () {
        if (this.companyInfo.previousAccreditationStart === null) {
          return null;
        }
        return moment(this.companyInfo.previousAccreditationStart).format("yyyy-MM-DD");
      },
      // setter
      set: function (newValue) {
        this.companyInfo.previousAccreditationStart = newValue;
      },
    },
    validationRules() {
      return this.from === "company-information"
        ? {
            companyInfo: {
              companyLegalName: {
                minLength: minLength(2),
                maxLength: maxLength(75),
                required,
              },
              dbaCompanyName: {
                minLength: minLength(2),
                maxLength: maxLength(75),
              },
              maskedFederalIDNumber: {
                //customTax,
                minLength: minLength(10),
              },
              website: {
                url,
              },
              locationTypes: {},
              programsOfInterest: {
                required,
              },
            },
          }
        : {
            companyInfo: {
              companyLegalName: {
                minLength: minLength(2),
                maxLength: maxLength(75),
                required,
              },
              dbaCompanyName: {
                minLength: minLength(2),
                maxLength: maxLength(75),
              },
              maskedFederalIDNumber: {
                minLength: minLength(10),
                required,
              },
              website: {
                url,
              },
              locationTypes: {},
              affiliates: {},
              dateEstablished: {
                required,
              },
              previousAccreditationExpiration: {
                required: requiredIf(function () {
                  return this.companyInfo.previouslyAccredited;
                }),
              },
              previousAccreditationStart: {
                required: requiredIf(function () {
                  return this.companyInfo.previouslyAccredited;
                }),
              },
              previouslyAccreditedBy: {
                required: requiredIf(function () {
                  return this.companyInfo.previouslyAccredited;
                }),
              },
              previouslyAccredited: {
                required,
              },
              programsOfInterest: {
                required,
              },
            },
            applicationCompanyDetail: {
              numberOfEmployees: {
                required,
              },
            },
            programsList: {
              required,
            },
            servicesList: {
              required,
            },
          };
    },
    validateLocationTypesSelected() {
      // Inverse the return data to show the validation message
      return !this.locationTypesAdded();
    },
  },
  beforeDestroy() {
    bus.$off("applicationStatus");
  },
};
</script>
