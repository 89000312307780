var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"form-full-width company-info-form"},[(_vm.from === 'company-information')?_c('h1',{staticClass:"bold-header-text app-header mb-3"},[(_vm.companyInfo.parentCompanyID)?_c('span',[_vm._v("Additional Site ")]):_c('span',[_vm._v("Company")]),_vm._v(" "+_vm._s(_vm.companyInfo.companyID)+" ")]):_vm._e(),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('LabelWithInfoTootlip',{attrs:{"label":_vm.$t('company.company-legal-name'),"tooltipText":"The legal name of your business must match paperwork sent to your state or federal regulatory bodies."}}),_c('b-form-input',{attrs:{"disabled":_vm.disableField,"type":"text","state":_vm.$v.companyInfo.companyLegalName.$dirty
              ? !_vm.$v.companyInfo.companyLegalName.$error
              : null},on:{"input":_vm.$v.companyInfo.companyLegalName.$touch},model:{value:(_vm.companyInfo.companyLegalName),callback:function ($$v) {_vm.$set(_vm.companyInfo, "companyLegalName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"companyInfo.companyLegalName"}}),(!_vm.$v.companyInfo.companyLegalName.maxLength)?_c('div',{staticClass:"text-danger"},[_vm._v(" Must be fewer than 75 characters. ")]):_vm._e(),(
            !_vm.$v.companyInfo.companyLegalName.required && _vm.$v.companyInfo.companyLegalName.$dirty
          )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('LabelWithInfoTootlip',{attrs:{"label":_vm.$t('company.dba-company-name'),"tooltipText":"The Doing Business As (DBA) name is also referred to as an assumed name, fictitious business name, or trade name."}}),_c('b-form-input',{attrs:{"disabled":_vm.disableField,"type":"text","state":_vm.$v.companyInfo.dbaCompanyName.$dirty ? !_vm.$v.companyInfo.dbaCompanyName.$error : null},on:{"input":_vm.$v.companyInfo.dbaCompanyName.$touch},model:{value:(_vm.companyInfo.dbaCompanyName),callback:function ($$v) {_vm.$set(_vm.companyInfo, "dbaCompanyName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"companyInfo.dbaCompanyName"}}),(!_vm.$v.companyInfo.dbaCompanyName.maxLength)?_c('div',{staticClass:"text-danger"},[_vm._v(" Must be fewer than 75 characters. ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('LabelWithInfoTootlip',{attrs:{"label":"Tax ID","tooltipText":"A Taxpayer Identification Number (TID) is a unique nine-digit number assigned by the Internal Revenue Service (IRS) to business entities operating in the United States and its territories."}}),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.taxMask),expression:"taxMask"}],attrs:{"disabled":_vm.disableField,"state":_vm.$v.companyInfo.maskedFederalIDNumber.$dirty
              ? !_vm.$v.companyInfo.maskedFederalIDNumber.$error
              : null},on:{"input":_vm.$v.companyInfo.maskedFederalIDNumber.$touch},model:{value:(_vm.companyInfo.maskedFederalIDNumber),callback:function ($$v) {_vm.$set(_vm.companyInfo, "maskedFederalIDNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"companyInfo.maskedFederalIDNumber"}}),(!_vm.$v.companyInfo.maskedFederalIDNumber.minLength)?_c('div',{staticClass:"text-danger",attrs:{"state":!_vm.$v.companyInfo.maskedFederalIDNumber.$error}},[_vm._v(" Must have at least 9 digits. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Organization Website"}},[_c('b-form-input',{attrs:{"disabled":_vm.disableField,"state":_vm.$v.companyInfo.website.$dirty ? !_vm.$v.companyInfo.website.$error : null},on:{"input":_vm.$v.companyInfo.website.$touch},model:{value:(_vm.companyInfo.website),callback:function ($$v) {_vm.$set(_vm.companyInfo, "website", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"companyInfo.website"}}),(!_vm.$v.companyInfo.website.url && _vm.$v.companyInfo.website.$dirty)?_c('div',{staticClass:"text-danger"},[_vm._v(" Must be a valid URL. ")]):_vm._e()],1)],1)],1),_c('b-row',[(_vm.from !== 'company-information')?_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"Incorporation Date"}},[_c('b-form-input',{attrs:{"disabled":_vm.disableField,"type":"date","state":_vm.$v.companyInfo.dateEstablished.$dirty ? !_vm.$v.companyInfo.dateEstablished.$error : null},on:{"input":_vm.$v.companyInfo.dateEstablished.$touch},model:{value:(_vm.dateEstablished),callback:function ($$v) {_vm.dateEstablished=$$v},expression:"dateEstablished"}}),(!_vm.$v.companyInfo.dateEstablished.required && _vm.$v.companyInfo.dateEstablished.$dirty)?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1):_vm._e()],1),(_vm.from === 'company-information')?_c('b-row',{staticClass:"mb-5"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Program(s) of Interest"}},[_c('multiselect',{class:{
            'multiselect-is-invalid': _vm.$v.companyInfo.programsOfInterest.$dirty
              ? _vm.$v.companyInfo.programsOfInterest.$error
              : null,
          },attrs:{"disabled":_vm.disableField,"options":_vm.programs,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":_vm.$t('dropdown.select-a-program'),"label":"programWebDescription","track-by":"programInitials","preselect-first":false},on:{"input":_vm.$v.companyInfo.programsOfInterest.$touch},model:{value:(_vm.companyInfo.programsOfInterest),callback:function ($$v) {_vm.$set(_vm.companyInfo, "programsOfInterest", $$v)},expression:"companyInfo.programsOfInterest"}},[_c('template',{slot:"selection"},[(_vm.$v.companyInfo.programsOfInterest.length && !_vm.isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(_vm.$v.companyInfo.programsOfInterest.length)+" programs selected ")]):_vm._e()])],2),(
            !_vm.$v.companyInfo.programsOfInterest.required &&
            _vm.$v.companyInfo.programsOfInterest.$dirty
          )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1)],1):_vm._e(),(_vm.from !== 'company-information')?_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"9"}},[_c('b-form-group',{attrs:{"label":"Program(s) of Interest"}},[_c('multiselect',{class:{
            'multiselect-is-invalid': _vm.$v.programsList.$dirty ? _vm.$v.programsList.$error : null,
          },attrs:{"disabled":_vm.disableField,"options":_vm.programOfInterest,"multiple":true,"close-on-select":false,"placeholder":"Select Programs...","track-by":"programID","group-values":"options","group-label":"label","preselect-first":false,"label":"programDescription"},on:{"input":_vm.programsUpdated,"remove":_vm.handleRemovePrograms},scopedSlots:_vm._u([{key:"option",fn:function(props){return [(props.option.$isLabel)?_c('span',{staticClass:"option__title",attrs:{"title":props.option.$groupLabel}},[_vm._v(_vm._s(props.option.$groupLabel))]):_c('span',{staticClass:"option__title",attrs:{"title":props.option.programDetailDescription}},[_vm._v(_vm._s(props.option.programDescription))])]}}],null,false,3842001332),model:{value:(_vm.programsList),callback:function ($$v) {_vm.programsList=$$v},expression:"programsList"}}),(!_vm.$v.programsList.required && _vm.$v.programsList.$dirty)?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('a',{staticClass:"plainButton",attrs:{"target":"_blank","href":"https://navigatordev.achc.org/app/standards"}},[_vm._v("Standards")])])],1):_vm._e(),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Location Type"}},[_c('multiselect',{class:{
            'multiselect-is-invalid': _vm.$v.companyInfo.locationTypes.$dirty
              ? _vm.$v.companyInfo.locationTypes.$error
              : null,
          },attrs:{"disabled":_vm.disableField,"options":_vm.locationTypes,"multiple":true,"close-on-select":false,"placeholder":_vm.$t('dropdown.select-a-location-type'),"track-by":"locationTypeID","label":"locationTypeDesc","group-values":"options","group-label":"label"},on:{"input":_vm.$v.companyInfo.locationTypes.$touch},model:{value:(_vm.companyInfo.locationTypes),callback:function ($$v) {_vm.$set(_vm.companyInfo, "locationTypes", $$v)},expression:"companyInfo.locationTypes"}}),(_vm.validateLocationTypesSelected)?_c('div',{staticClass:"text-danger mt-1"},[_vm._v(" One location type for each program(s) of interest is required ")]):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[(_vm.from === 'company-information')?_c('b-col',[_c('b-form-group',{attrs:{"label":"Affiliate(s)"}},[_c('multiselect',{attrs:{"disabled":_vm.disableField,"options":_vm.affiliates,"multiple":true,"close-on-select":false,"placeholder":_vm.$t('dropdown.select-an-affiliate'),"track-by":"affiliateID","label":"companyName"},model:{value:(_vm.companyInfo.affiliates),callback:function ($$v) {_vm.$set(_vm.companyInfo, "affiliates", $$v)},expression:"companyInfo.affiliates"}})],1)],1):_vm._e()],1),(_vm.from !== 'company-information')?_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('b-form-group',[_c('LabelWithInfoTootlip',{attrs:{"label":"Service(s) of Interest","tooltipText":"Distinctions require meeting additional standard requirements and incurring added costs. Please reach out to your dedicated account representative for more information."}}),_c('multiselect',{class:{
            'multiselect-is-invalid': _vm.$v.servicesList.$dirty ? _vm.$v.servicesList.$error : null,
          },attrs:{"options":_vm.servicesMappedToPrograms,"disabled":_vm.isServiceDropdownDisabled,"multiple":true,"close-on-select":false,"placeholder":"Select Services...","track-by":"serviceID","group-values":"options","group-label":"label","preselect-first":false,"label":"serviceDescription"},on:{"input":_vm.$v.servicesList.$touch},scopedSlots:_vm._u([{key:"option",fn:function(props){return [(props.option.$isLabel)?_c('span',{staticClass:"option__title",attrs:{"title":props.option.$groupLabel}},[_vm._v(_vm._s(props.option.$groupLabel))]):_c('span',{staticClass:"option__title",attrs:{"title":props.option.serviceDescription}},[_vm._v(_vm._s(props.option.serviceDescription))])]}}],null,false,3893977701),model:{value:(_vm.servicesList),callback:function ($$v) {_vm.servicesList=$$v},expression:"servicesList"}}),(!_vm.$v.servicesList.required && _vm.$v.servicesList.$dirty)?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1)],1):_vm._e(),_c('b-row',{staticStyle:{"align-items":"end"}},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Is this location currently accredited by an Accrediting Organization\n      (AO), other than ACHC, for the programs and/or services being applied for?"}}),_c('b-form-select',{staticClass:"form-select",attrs:{"disabled":_vm.disableField,"options":_vm.yesNo,"text-field":"text","value-field":"value","state":_vm.$v.companyInfo.previouslyAccredited.$dirty
            ? !_vm.$v.companyInfo.previouslyAccredited.$error
            : null},on:{"input":_vm.$v.companyInfo.previouslyAccredited.$touch},model:{value:(_vm.companyInfo.previouslyAccredited),callback:function ($$v) {_vm.$set(_vm.companyInfo, "previouslyAccredited", $$v)},expression:"companyInfo.previouslyAccredited"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('LabelWithInfoTootlip',{attrs:{"label":"\n          Total Number of Employees","tooltipText":"Include all full time and part time employees."}}),_c('b-form-group',{staticStyle:{"margin-bottom":"auto"}},[_c('b-form-input',{attrs:{"disabled":_vm.disableField,"type":"number","min":"0","state":_vm.$v.applicationCompanyDetail.numberOfEmployees.$dirty
              ? !_vm.$v.applicationCompanyDetail.numberOfEmployees.$error
              : null},on:{"input":_vm.$v.applicationCompanyDetail.numberOfEmployees.$touch},model:{value:(_vm.applicationCompanyDetail.numberOfEmployees),callback:function ($$v) {_vm.$set(_vm.applicationCompanyDetail, "numberOfEmployees", $$v)},expression:"applicationCompanyDetail.numberOfEmployees"}})],1)],1)],1),_c('b-row',[_c('b-col',[(
          !_vm.$v.companyInfo.previouslyAccredited.required &&
          _vm.$v.companyInfo.previouslyAccredited.$dirty
        )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()]),_c('b-col',[(
          !_vm.$v.applicationCompanyDetail.numberOfEmployees.required &&
          _vm.$v.applicationCompanyDetail.numberOfEmployees.$dirty
        )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[(_vm.companyInfo.previouslyAccredited)?_c('b-form-group',{attrs:{"label":"Accreditation Organization"}},[_c('b-form-select',{staticClass:"form-select",attrs:{"disabled":_vm.disableField,"options":_vm.accreditationOrganizations,"text-field":"displayName","value-field":"initialism","state":_vm.$v.companyInfo.previouslyAccreditedBy.$dirty
              ? !_vm.$v.companyInfo.previouslyAccreditedBy.$error
              : null},on:{"input":_vm.$v.companyInfo.previouslyAccreditedBy.$touch},model:{value:(_vm.companyInfo.previouslyAccreditedBy),callback:function ($$v) {_vm.$set(_vm.companyInfo, "previouslyAccreditedBy", $$v)},expression:"companyInfo.previouslyAccreditedBy"}}),(
            !_vm.$v.companyInfo.previouslyAccreditedBy.required &&
            _vm.$v.companyInfo.previouslyAccreditedBy.$dirty
          )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1):_vm._e()],1),(_vm.companyInfo.previouslyAccredited)?_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"Accreditation Start Date"}},[_c('b-form-input',{attrs:{"disabled":_vm.disableField,"type":"date","state":_vm.$v.companyInfo.previousAccreditationStart.$dirty
              ? !_vm.$v.companyInfo.previousAccreditationStart.$error
              : null},on:{"input":_vm.$v.companyInfo.previousAccreditationStart.$touch},model:{value:(_vm.previousAccreditationStart),callback:function ($$v) {_vm.previousAccreditationStart=$$v},expression:"previousAccreditationStart"}}),(
            !_vm.$v.companyInfo.previousAccreditationStart.required &&
            _vm.$v.companyInfo.previousAccreditationStart.$dirty
          )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1):_vm._e(),(_vm.companyInfo.previouslyAccredited)?_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"Accreditation End Date"}},[_c('b-form-input',{attrs:{"disabled":_vm.disableField,"type":"date","state":_vm.$v.companyInfo.previousAccreditationExpiration.$dirty
              ? !_vm.$v.companyInfo.previousAccreditationExpiration.$error
              : null},on:{"input":_vm.$v.companyInfo.previousAccreditationExpiration.$touch},model:{value:(_vm.previousAccreditationExpiration),callback:function ($$v) {_vm.previousAccreditationExpiration=$$v},expression:"previousAccreditationExpiration"}}),(
            !_vm.$v.companyInfo.previousAccreditationExpiration.required &&
            _vm.$v.companyInfo.previousAccreditationExpiration.$dirty
          )?_c('div',{staticClass:"text-danger"},[_vm._v(" This field is required. ")]):_vm._e()],1)],1):_vm._e()],1),(_vm.from === 'company-information')?_c('b-row',[_c('b-col',{staticClass:"save-button-container"},[_c('api-button-overlay',{attrs:{"processing":_vm.processing}},[_c('button',{staticClass:"btn btn-blue",attrs:{"disabled":_vm.formLoading},on:{"click":_vm.validateAndSaveCompanyInfo}},[_vm._v(" "+_vm._s(_vm.textButton)+" ")])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }