<template>
  <company-contacts
    ref="CompanyContactRef"
    :tableColumns="tableColumns"
    addNewRecordTitle="Add New Company Contact"
    editRecordTitle="Edit Company Contact for - "
    deleteRecordTitle="Delete Application Company Contact"
    showCompanyId:false
    from="application-company-contacts"
  />
</template>
<script>
import CompanyContacts from "../../../components/App/Company/CompanyContacts.component.vue";
export default {
  props: [],
  data() {
    return {
      tableColumns: [
        { cell: "myTemplate", title: "Actions", width: "100" },
        { field: "companyID", title: "Co.ID", width: "70" },
        { field: "contactID", title: "Contact ID", width: "100" },
        {
          field: "firstName",
          title: "First Name",
          width: "110",
        },
        {
          field: "lastName",
          title: "Last Name",
          width: "110",
        },
        {
          field: "contactTypeDescriptions",
          title: "Contact Type",
          cell: "contactTypeDescriptionsTemplete",
          width: "310",
        },
        // {
        //   field: "phoneNumbers",
        //   title: "Phone Numbers",
        //   cell: "phoneNumbersTemplate",
        //   width: "140",
        // },
        // {
        //   field: "emailAddress",
        //   title: "Email Address",
        //   width: "160",
        // },
        // {
        //   field: "notes",
        //   title: "Notes",
        //   cell: "notesTemplate",
        //   width: "70",
        // },
      ],
    };
  },
  components: {
    CompanyContacts,
  },
  methods: {
    async isValidationSuccess() {
      const isAvailable = await this.$refs.CompanyContactRef.isAllThreeContactsAvailable();
      const isAdded = await this.$refs.CompanyContactRef.areAllProgramSpecificOptionsAdded();
      return isAvailable && isAdded;
    },
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>
