<template>
  <b-form-checkbox-group
    :id="`checkbox-group-${questionID}`"
    v-model="v.questionsForm[`question_${questionID}`].$model"
    :options="selections"
    :name="`checkbox-group-${questionID}`"
    :state="validateField(`question_${questionID}`)"
    :disabled="disabled"
  ></b-form-checkbox-group>
</template>
<script>
export default {
  props: ["questionID", "validateField", "v", "selections", "disabled"],
};
</script>
