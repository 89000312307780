<template>
  <div class="full-width">
    <company-info
      from="main-application-company-information"
      :parentApplicationCompanyID="parentApplicationCompanyID"
      ref="CompanyInfoRef"
    />
    <company-phone
      from="main-application-company-phone"
      :parentApplicationCompanyID="parentApplicationCompanyID"
    />
    <company-address
      from="main-application-company-address"
      ref="CompanyAddress"
      :parentApplicationCompanyID="parentApplicationCompanyID"
    />
  </div>
</template>
<script>
import CompanyInfo from "../../../components/App/Company/CompanyInfo.component.vue";
import CompanyPhone from "../../../components/App/Company/Companyphone.component.vue";
import CompanyAddress from "../../../components/App/Company/Companyaddress.component.vue";

export default {
  props: ["parentApplicationCompanyID"],
  components: {
    CompanyInfo,
    CompanyPhone,
    CompanyAddress,
  },
  methods: {
    async saveCompanyDetails() {
      await this.$refs.CompanyInfoRef.saveCompanyInfoDetails();
      const isCompanyInfoValidated = this.$refs.CompanyInfoRef.validateCompanyInfoDetails();
      const areLocationTypesAdded = this.$refs.CompanyInfoRef.locationTypesAdded();
      const isCompanyAddressValidated = this.$refs.CompanyAddress.isAllThreeAddressAvailable();
      // If all are filled properly then it will be considered as completed
      return isCompanyInfoValidated && areLocationTypesAdded && isCompanyAddressValidated;
    },
  },
};
</script>
<style scoped>
.full-width {
  width: 100%;
}
</style>
