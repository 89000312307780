<template>
  <multiselect
    :class="{
      'multiselect-is-invalid': v.questionsForm[`question_${questionID}`].$dirty
        ? v.questionsForm[`question_${questionID}`].$error
        : null,
    }"
    v-model="v.questionsForm[`question_${questionID}`].$model"
    :options="selections"
    :multiple="true"
    :close-on-select="false"
    placeholder="Select..."
    track-by="value"
    label="text"
    :preselect-first="false"
    :disabled="disabled"
    @input="v.questionsForm[`question_${questionID}`].$touch"
  >
    <template slot="option" slot-scope="props">
      <span v-if="props.option.tooltip" :title="props.option.tooltip">{{ props.option.text }}</span>
      <span v-else>{{ props.option.text }}</span>
    </template>
  </multiselect>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
  props: ["questionID", "validateField", "v", "selections", "disabled"],
  components: {
    multiselect: Multiselect,
  },
};
</script>
<style lang="scss" scoped>
.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #d02c23 !important;
}
</style>
