<template>
  <b-form-input
    :type="type"
    v-model.trim="v.questionsForm[`question_${questionID}`].$model"
    :state="validateField(`question_${questionID}`)"
    :disabled="disabled"
  />
</template>
<script>
export default {
  props: ["questionID", "validateField", "v", "type", "disabled"],
};
</script>
