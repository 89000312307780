<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      servicesMappedToPrograms: [],
      statusCode: null,
    };
  },
  methods: {
    ...mapMutations(["setDangerMessage"]),
    async getServicesMappedToPrograms(programIDs) {
      await axios
        .get(`${WebAPIUrl}/api/service/grouped-by-program/${programIDs}`)
        .then((result) => {
          this.servicesMappedToPrograms = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
  },
};
</script>
