<template>
  <section class="full-width">
    <div class="additional-site-grid-header mb-3">
      <confirmation ref="Confirmation"></confirmation>
      <h1 class="bold-header-text app-header">Company Phone</h1>
      <button
        class="base-button"
        @click="openCompanyPhoneModal"
        :disabled="disableField"
        v-if="from !== 'main-application-company-phone'"
      >
        Add New
      </button>
    </div>
    <p class="base-header-text" v-if="from !== 'main-application-company-phone'">
      All Phones associated with your companies must be included on an application. Click the
      <strong>Add New</strong> button above to get started.
    </p>
    <b-card class="dashboard-card">
      <kendo-grid
        id="grid1"
        ref="grid"
        :sortable="true"
        :sort="sort"
        :data-items="gridData"
        :selectable="true"
        :columns="
          from === 'main-application-company-phone'
            ? tableColumns.filter(({ title }) => title !== 'Actions')
            : tableColumns
        "
        @datastatechange="dataStateChange"
        :resizable="true"
      >
        <template v-slot:phoneNumberTemplate="{ props }">
          <td class="text-center">
            {{ props.dataItem.phoneNumber | VMask(phoneMask) }}
          </td>
        </template>
        <template v-slot:myTemplate="{ props }">
          <td class="k-command-cell">
            <custom :data-item="props.dataItem" @edit="editCompany" @remove="deleteCompany" />
          </td>
        </template>
      </kendo-grid>

      <CompanyPhoneModal ref="CompanyPhoneModal"></CompanyPhoneModal>
    </b-card>
  </section>
</template>
<script>
import { process } from "@progress/kendo-data-query";
import { Grid } from "@progress/kendo-vue-grid";
//import { ContextMenu } from "@progress/kendo-layout-vue-wrapper";
import PhoneMixin from "../../../mixins/PhoneMixin.vue";
import CompanyPhoneMixin from "../../../mixins/CompanyPhoneMixin.vue";
import Confirmation from "../../../components/Common/Confirmation.vue";
import ButtonActions from "../../../components/Common/ButtonActions.vue";
import { mapGetters } from "vuex";
import CompanyPhoneModal from "../../../components/Modals/CompanyPhoneModal.vue";
import { getCurrentCompany } from "../../../utils/customFunctions";
import { bus } from "../../../main";
export default {
  props: {
    from: {
      type: String,
      default: "company-phone",
    },
    parentApplicationCompanyID: {
      type: Number,
    },
  },
  components: {
    "kendo-grid": Grid,
    //"kendo-contextmenu": ContextMenu,
    CompanyPhoneModal,
    Confirmation,
    custom: ButtonActions,
  },
  mixins: [PhoneMixin, CompanyPhoneMixin],
  async mounted() {
    bus.$on("applicationStatus", async (data) => {
      this.disableField = data;
      if (this.from === "main-application-company-phone") {
        if (data && this.parentApplicationCompanyID) {
          await this.getApplicationSubmitPhonesForCompany(this.parentApplicationCompanyID);
          this.getData(this.applicationSubmitCompanyPhones);
        } else {
          await this.getPhonesForCompany(this.companyID);
          this.getData(this.companyPhoneTable);
        }
      }
    });
  },
  data() {
    return {
      tableColumns: [
        { cell: "myTemplate", title: "Actions", width: "120" },
        {
          title: "Phone Number",
          cell: "phoneNumberTemplate",
          width: "auto",
        },
        {
          field: "phoneNumberExtension",
          title: "Ext",
          width: "130",
        },
        {
          field: "phoneNumberTypeDesc",
          title: "Phone Type",
          width: "auto",
        },
      ],
      gridData: [],
      sort: [],
      companyID: null,
      disableField: false,
    };
  },
  async created() {
    bus.$emit("showFullpageOverlay", {
      showLayover: true,
    });
    const { companyID = 0 } = getCurrentCompany();
    this.companyID = companyID;
    if (this.from !== "company-phone") {
      this.companyID = this.$route.params.companyID;
    }
    if (this.from !== "main-application-company-phone") {
      await this.getPhonesForCompany(this.companyID);
      this.getData(this.companyPhoneTable);
    }
    bus.$emit("showFullpageOverlay", {
      showLayover: false,
    });
  },
  methods: {
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    getData(data) {
      this.gridData = process(data, {
        sort: this.sort,
      });
    },
    openCompanyPhoneModal() {
      this.$refs.CompanyPhoneModal.show({
        title: `Add New Company Phone`,
        companyID: this.companyID,
        exitFunction: async () => {
          await this.getPhonesForCompany(this.companyID);
          this.getData(this.companyPhoneTable);
        },
      });
    },
    editCompany(dataItem) {
      this.$refs.CompanyPhoneModal.show({
        title: `Edit/View For Phone ID: ${dataItem.companyPhoneID} `,
        companyPhoneID: dataItem.companyPhoneID,
        companyID: this.companyID,
        exitFunction: async () => {
          await this.getPhonesForCompany(this.companyID);
          this.getData(this.companyPhoneTable);
        },
      });
    },
    deleteCompany(dataItem) {
      this.$refs.Confirmation.show({
        title: "Delete Company Phone",
        message: `Are you sure you want to delete ${this.formatPhoneNumber(dataItem.phoneNumber)}?`,
        okButton: "Yes",
        cancelButton: "No",
        exitFunction: () => {},
      }).then(async (result) => {
        if (result) {
          this.setInfoMessage("");
          await this.deleteCompanyPhone(dataItem.companyPhoneID).then(async () => {
            await this.getPhonesForCompany(this.companyID);
            this.getData(this.companyPhoneTable);
          });
        }
      });
    },
    async createAppState(dataState) {
      await this.getPhonesForCompany(this.companyID);
      this.sort = dataState.sort;
      this.getData(this.companyPhoneTable);
    },
    async dataStateChange(event) {
      await this.createAppState(event.data);
    },
  },
  computed: {
    ...mapGetters({
      getCompanyPhone: "getCompanyPhone",
    }),
    companyPhoneTable() {
      return this.getCompanyPhone;
    },
  },
  beforeDestroy() {
    bus.$off("applicationStatus");
  },
};
</script>
